import React from 'react';
import Api from '../apis/api';
import Utils from '../components/Utils';

import EventLine from '../components/js/EventLine';
import Button from '../components/common/Button';

import { toast } from 'react-toastify';
import queryString from 'query-string';
import DatePicker,{ registerLocale, setDefaultLocale } from "react-datepicker";
import { pt } from 'date-fns/locale';
import { CSVLink } from "react-csv";
registerLocale('pt', pt);
setDefaultLocale('pt');

const csvHeaders = [
    { label: "Nome", key: "username" },
    { label: "Email", key: "email" },
    { label: "Pago", key: "is_buy" },
    { label: "Presença", key: "has_attendance" }
  ];

class ScreenEventsAttendances extends React.Component {

    constructor(props) {
        super(props);

        const parsed = queryString.parse(this.props.location.search);

        const dateNow = new Date();
        const dateStart = new Date(dateNow.getUTCFullYear() + '-' + (dateNow.getUTCMonth() + 1) + '-' + dateNow.getUTCDate() + ' 00:00:00');
        const dateEnd = new Date(dateNow.getUTCFullYear() + '-' + (dateNow.getUTCMonth() + 1) + '-' + dateNow.getUTCDate() + ' 23:59:59');
   
        this.state = {
            loading: false,
            error: 0,

            attendance: [],
            dateStart,
            dateEnd,
            eventId: parsed.id,
            eventName: decodeURIComponent(parsed.name),
            datePeriod: decodeURIComponent(parsed.datePeriod),
            csvData: []
        };

    }

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        this.setState({ loading: true, attendance: [] });

        let dateStart = 0, dateEnd = 0;
        if (this.state.dateStart !== null) {
            dateStart = this.state.dateStart.getTime() / 1000;
        }
        if (this.state.dateEnd !== null) {
            dateEnd = this.state.dateEnd.getTime() / 1000;
        }

        let attendance = await Api.eventsAttendancesPanelList(this.state.eventId, dateStart, dateEnd, 1, 300);

        attendance = attendance.events_attendances;
        if (Utils.checkForErrors(this, attendance)) { return; };

        attendance = attendance.data.panel_list;
        if (Utils.checkForErrors(this, attendance)) { return; };

        attendance = attendance.data.list;

        // Monta a lista para exportação
        let csvData = [];
        attendance.forEach(element => {
           
            csvData.push({username: element.username, email: element.email, is_buy: (element.is_buy ? "sim" : "não"), has_attendance: ''})
        });
        //-----------------------------

        this.setState({ attendance, csvData }, () => this.setState({ loading: false }));
    }

    render() {

        return (
            <div className="main-content" style={{ textAlign: 'center', marginLeft: '1rem', marginRight: '1rem' }}>
                <label className="label-section" style={{fontSize: 20, fontWeight:'bold'}}>Evento de {this.state.eventName} ({this.state.datePeriod})</label>
                <div className='form min-600 centered' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div className="input-group-section" style={{ width: '30%', margin: '1rem' }}>
                        <label className="label-section">data do evento</label>
                        <DatePicker
                            selected={this.state.dateStart}
                            onChange={date => { this.handleChangeDate('start', date) }}
                            dateFormat="dd/MM/yyyy"
                        />
                    </div>
                    <Button
                        style={{ width: '15%' }}
                        onClick={() => { this.fetchData(); }}
                        type="orange"
                        title="Filtrar" />
                    &emsp;
                    <CSVLink data={this.state.csvData} headers={csvHeaders} 
                        separator={";"}
                        filename={"Lista de Presença.csv"}
                        style={{ width: '15%' }}
                        className={"button orange-bg"}
                        target="_blank">
                            Exportar
                    </CSVLink>
                </div>
                <div id='transactions-log'>
                    <EventLine 
                        data={[
                            {content: 'Name', size: 40}, 
                            {content: 'Email', size: 40}, 
                            {content: 'Pago', size: 10, center: true}, 
                            {content: 'Presença', size: 10, center: true}
                        ]}
                        font='bold'
                    />
                    {this.renderAttendanceList()}
                </div>

            </div>
        );
    }

    renderAttendanceList() {
        let list = this.state.attendance.map((item, i) => {

            let dataArray = [];
            dataArray.push({content: item.username, size: 40});
            dataArray.push({content: item.email, size: 40});
            dataArray.push({content: (item.is_buy ? "sim" : "não"), size: 10, center: true});
            dataArray.push({content: (item.has_attendance ? "sim" : "não"), size: 10, hasCheck: true, center: true});

            let classLine = '';
            if (i%2 !== 0) {
                classLine = 'white-line';
            } else {
                classLine = 'orange-line';
            }
            
            //console.log(dataArray);
            return (
                <EventLine 
                    classStyle={classLine}
                    key={i}
                    data={dataArray}
                    item={item}
                    eventId={this.state.eventId}
                    date={this.state.dateStart}
                />
            );
        });

        return list;
    }

    handleChangeDate = (type, date) => {

        if ((date) && (date != '')) {

            const dateStart = new Date(date.getUTCFullYear() + '-' + (date.getUTCMonth() + 1) + '-' + date.getUTCDate() + ' 00:00:00');
            const dateEnd = new Date(date.getUTCFullYear() + '-' + (date.getUTCMonth() + 1) + '-' + date.getUTCDate() + ' 23:59:59');

            this.setState({ dateStart, dateEnd },
                () => this.fetchData());
        }
        else {

            toast.info('A data do controle de presença não pode ser vazia, verifique.', {
                className: 'toast-info'
            });
        }
    }
}



export default ScreenEventsAttendances;