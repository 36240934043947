import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextareaAutosize from 'react-autosize-textarea';

import Button from '../common/Button';



class QuestionItem extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: '',
            index: '',

            type: '',
            question: '',
            options: [],
            answer: ''
        }
    };

    componentDidMount = () => {

        const { item, index } = this.props;

        if (!item) { return; }

        this.setState({
            id: item.id,
            index: index,

            type: item.type,
            question: item.question,
            options: item.options,
            answer: item.answer
        });
    }

    componentDidUpdate = (previousProps, previousState) => {
        if (previousProps !== this.props) {
            const { item, index } = this.props;

            if (!item) { return; }

            this.setState({
                id: item.id,
                index: index,

                type: item.type,
                question: item.question,
                options: item.options,
                answer: item.answer
            });
        }
    }



    render() {
        return (
            <div className="question-item">
                <label className="label-section">pergunta (*)</label>
                <TextareaAutosize 
                    value={this.state.question}
                    onChange={e => {
                        let text = e.target.value;
                         this.setState({ question: text },
                             () => this.props.onQuestionEdit(this.state.index, 'question', text ));

                        //this.props.onQuestionEdit(this.state.index, 'question', text );
                    }}
                    placeholder="digite aqui o enunciado da questão..."
                    async />
                <ul className="option-container">
                    {this.renderQuestionOptions()}
                    {this.renderActions()}
                </ul>
                <div className="actions">
                    <div className="edit-icon" onClick={this.handleDeleteQuestion}>
                        <FontAwesomeIcon icon={'times'} />
                    </div>
                </div>
            </div>
        );
    }

    renderQuestionOptions() {
        let list = this.state.options.map((optionGeneral, indexGeneral) => {
            return (
                <div className="option-item" key={indexGeneral}>
                    <input 
                        type="radio" 
                        checked={this.state.answer == indexGeneral}
                        value={indexGeneral}
                        onChange={e => {
                            let answer = parseInt(e.target.value);
                            // this.setState({ answer: answer },
                            // () => this.props.onQuestionEdit(this.state.index, 'answer', answer ));
                            this.props.onQuestionEdit(this.state.index, 'answer', answer );
                        }}
                        />
                    <TextareaAutosize
                        value={optionGeneral}
                        onChange={e => {

                            let text = e.target.value;
                            // let options = this.state.options.map((opt, i) => {
                            //     if (i === indexGeneral) { return text; } 
                            //     else { return opt; }
                            // });

                            // this.setState({ options: options },
                            // () => this.props.onQuestionEdit(this.state.index, 'options', text, indexGeneral ));

                            this.props.onQuestionEdit(this.state.index, 'options', text, indexGeneral, 'edit');
                        }} 
                        placeholder='Sem texto'
                        />
                    <FontAwesomeIcon 
                        onClick={() => {
                            this.props.onQuestionEdit(this.state.index, 'options', '', indexGeneral, 'remove');
                            //this.setState({ options: [...this.state.options.slice(0, indexGeneral), ...this.state.options.slice(indexGeneral + 1)]})
                        }}
                        icon={'times'} 
                        className="remove-icon"
                        />
                </div>
            );
        });

        return list;
    }

    renderActions() {
        if (this.state.options.length >= 5) { return; }

        return (
            <Button 
                onClick={() => {
                    this.props.onQuestionEdit(this.state.index, 'options', '', null, 'add');
                    //this.setState({ options: [...this.state.options, ''] })
                }}
                type="orange"
                icon={'plus'}
                />
        );
    }



    handleDeleteQuestion = () => {
        this.props.onDeleteQuestion(this.state.index);
    }

}


export default QuestionItem;