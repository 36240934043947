import React from 'react';
import Api from '../apis/api';

import { API_ROOT } from '../config';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';

import { uploadFile } from 'react-s3';
import Utils from '../components/Utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import TextareaAutosize from 'react-autosize-textarea';
import Button from '../components/common/Button';

import DatePicker,{ registerLocale, setDefaultLocale } from "react-datepicker";
import { pt } from 'date-fns/locale';
registerLocale('pt', pt);
setDefaultLocale('pt');

class ScreenEventEdit extends React.Component {

    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.focusTextInput = this.focusTextInput.bind(this);
        this.inputs = {};

        this.state = {
            loading: false,
            error: 0,

            id: '',

            name: '',
            description: '',
            description_short: '',
            image: '',
            images: [],

            date_period: '',

            local_name: '',
            local_street: '',
            local_number: '',
            local_complement: '',
            local_neighborhood: '',
            local_city: '',
            local_state: '',
            local_zipcode: '',
            local_country: 'Brasil',

            link: '',

            lot_price_1: '',
            lot_date_1: null,
            lot_price_2: '',
            lot_date_2: null,
            lot_price_3: '',
            lot_date_3: null,

            isLink: false,
            isPresential: false,

            redirect: false,

            allEvents: []
        };
    };
    
    componentDidMount() {

        this.setState({
            redirect: false
        });

        const parsed = queryString.parse(this.props.location.search);

        if ('id' in parsed) {
            this.fetchData(parsed.id);
        }

        this.fetchListElements();

        this.focusTextInput('name');
    };

    focusTextInput(id) {

        this.inputs[id].focus();
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });

        if (value) {

            setTimeout(() => {
                this.focusTextInput((name === 'isLink') ? 'link' : 'localName');
            }, 500);
        }
    }

    render() {       

        if (this.state.error !== 0) {
            return null;
        };


        return (
            <div className="main-content min-600 centered form">
                {/* Name */}
                <div className="input-group-section">
                    <label className="label-section">nome (*)</label>
                    <input 
                        type="text"
                        //ref={this.nameInput}
                        ref={input => {this.inputs['name'] = input;}}
                        maxLength={150}
                        value={this.state.name}
                        onChange={e => this.setState({ name: e.target.value}) }
                        placeholder="digite aqui o nome do evento..." />
                </div>

                {/* Description */}
                <div className="input-group-section">
                    {/* Short */}
                    <label className="label-section">descrição curta (*)</label>
                    <TextareaAutosize 
                        value={this.state.description_short}
                        onChange={e => this.setState({ description_short: e.target.value }) }
                        placeholder="digite aqui uma descrição curta p/ o evento..." />
                </div>
                <div className="input-group-section">
                    {/* Long */}
                    <label className="label-section">descrição longa</label>
                    <TextareaAutosize 
                        value={this.state.description}
                        onChange={e => this.setState({ description: e.target.value }) }
                        placeholder="digite aqui uma descrição detalhada p/ o evento..." />
                </div>

                {/* Number values */}
                <div className="input-group-section" style={{ display: 'flex', justifyContent: 'space-between'}}>
                    {/* date_period */}
                    <div className="common-group-item" style={{ width: '100%'}}>
                        <label className="label-section">data e hora (*)</label>
                        <input 
                            type="text"
                            maxLength={100}
                            value={this.state.date_period}
                            onChange={e => this.setState({ date_period: e.target.value}) }
                            placeholder="digite aqui a data e horário do evento..." />
                    </div>
                </div>

                <div className="input-group-section" style={{padding: 0, margin: 0}}>
                    <div className="common-group-item" style={{ height: 20, width: '100%'}}>
                        <label className="label-section">
                            categoria de preços
                        </label>
                    </div>
                </div>

                {/* Categorias de preços */}
                <div className="input-group-section" style={{margin: 0, padding: 0, paddingLeft: 10, paddingRight: 10, backgroundColor: '#ffede6', borderRadius: 10}}>
                    <div className="input-group-section" style={{ margin: 0, padding: 10, display: 'flex', justifyContent: 'space-between'}}>
                        
                        {/* lot_price_1 */}
                        <div className="common-group-item" style={{ width: '33%'}}>
                            <label className="label-section">valor lote 1 em reais (ex.: 250,50) (*)</label>
                            <input 
                                type="number"
                                value={this.state.lot_price_1}
                                min='1' max='999999'
                                onChange={e => this.setState({ lot_price_1: e.target.value}) } />
                        </div>
                        {/* lot_price_2 */}
                        <div className="common-group-item" style={{ width: '33%'}}>
                            <label className="label-section">valor lote 2 em reais (ex.: 250,50)</label>
                            <input 
                                type="number"
                                value={this.state.lot_price_2}
                                min='1' max='999999'
                                onChange={e => this.setState({ lot_price_2: e.target.value}) } />
                        </div>
                        {/* lot_price_3 */}
                        <div className="common-group-item" style={{ width: '33%'}}>
                            <label className="label-section">valor lote 3 em reais (ex.: 250,50)</label>
                            <input 
                                type="number"
                                value={this.state.lot_price_3}
                                min='1' max='999999'
                                onChange={e => this.setState({ lot_price_3: e.target.value}) } />
                        </div>
                    </div>
                    <div className="input-group-section" style={{ margin: 0, padding: 10, display: 'flex', justifyContent: 'space-between'}}>
                        
                        {/* lot_date_1 */}
                        <div className="common-group-item" style={{ width: '33%'}}>
                            <label className="label-section">data lote 1</label>
                            <DatePicker
                                selected={this.state.lot_date_1}
                                onChange={date => { this.setState({ lot_date_1: date}) }}
                                dateFormat="dd/MM/yyyy"
                            />
                        </div>
                        {/* lot_date_2 */}
                        <div className="common-group-item" style={{ width: '33%'}}>
                            <label className="label-section">data lote 2</label>
                            <DatePicker
                                selected={this.state.lot_date_2}
                                onChange={date => { this.setState({ lot_date_2: date}) }}
                                dateFormat="dd/MM/yyyy"
                            />
                        </div>
                        {/* lot_date_3 */}
                        <div className="common-group-item" style={{ width: '33%'}}>
                            <label className="label-section">data lote 3</label>
                            <DatePicker
                                selected={this.state.lot_date_3}
                                onChange={date => { this.setState({ lot_date_3: date}) }}
                                dateFormat="dd/MM/yyyy"
                            />
                        </div>
                    </div>
                </div>
                
                <div className="input-group-section" style={{padding: 0, margin: 0, paddingTop: 35}}>
                    <div className="common-group-item" style={{ height: 20, width: '100%'}}>
                        <label className="label-section">
                            <input
                                name="isLink"
                                type="checkbox"
                                checked={this.state.isLink}
                                onChange={this.handleInputChange} />
                            localização do curso a distância
                        </label>
                    </div>
                </div>

                {/* link */}
                <div className="input-group-section" style={{margin: 0, padding: 10, backgroundColor: '#ffede6', borderRadius: 10}}>
                    <div className="common-group-item" style={{width: '100%', paddingLeft: 10, paddingRight:10}}>
                        <label className="label-section" style={{paddingLeft: 10}}>link</label>
                        <input 
                            type="text"
                            ref={input => {this.inputs['link'] = input;}}
                            maxLength={150}
                            disabled={!this.state.isLink}
                            value={this.state.link}
                            onChange={e => this.setState({ link: e.target.value})}
                            placeholder="digite aqui o link..." />
                    </div>
                </div>
                
                <div className="input-group-section" style={{padding: 0, margin: 0, marginTop: 10, paddingTop: 30}}>
                    <div className="common-group-item" style={{ height: 20, width: '100%'}}>
                        <label className="label-section">
                            <input
                                name="isPresential"
                                type="checkbox"
                                checked={this.state.isPresential}
                                onChange={this.handleInputChange} />
                            localização do curso presencial
                        </label>
                    </div>
                </div>

                <div className="input-group-section" style={{margin: 0, padding: 0, paddingLeft: 10, paddingRight: 10, backgroundColor: '#ffede6', borderRadius: 10}}>
                    {/* local_name */}
                    <div className="input-group-section" style={{margin: 0, padding: 10}}>
                        <div className="common-group-item" style={{ width: '100%'}}>
                            <label className="label-section">nome do local</label>
                            <input 
                                type="text"
                                ref={input => {this.inputs['localName'] = input;}}
                                maxLength={150}
                                disabled={!this.state.isPresential}
                                value={this.state.local_name}
                                onChange={e => this.setState({ local_name: e.target.value}) }
                                placeholder="digite aqui o nome do local..." />
                        </div>
                    </div>

                    {/* Endereço */}
                    <div className="input-group-section" style={{ margin: 0, padding: 10, display: 'flex', justifyContent: 'space-between'}}>
                        {/* local_street */}
                        <div className="common-group-item" style={{ width: '60%'}}>
                            <label className="label-section">endereço</label>
                            <input 
                                type="text"
                                maxLength={100}
                                disabled={!this.state.isPresential}
                                value={this.state.local_street}
                                onChange={e => this.setState({ local_street: e.target.value}) }
                                placeholder="digite aqui o endereço..." />
                        </div>
                        {/* local_number */}
                        <div className="common-group-item" style={{ width: '10%'}}>
                            <label className="label-section">número</label>
                            <input 
                                type="number"
                                disabled={!this.state.isPresential}
                                value={this.state.local_number}
                                min='1' max='999999'
                                onChange={e => this.setState({ local_number: e.target.value}) } />
                        </div>
                        {/* complement */}
                        <div className="common-group-item" style={{ width: '20%'}}>
                            <label className="label-section">complemento</label>
                            <input 
                                type="text"
                                maxLength={40}
                                disabled={!this.state.isPresential}
                                value={this.state.local_complement}
                                onChange={e => this.setState({ local_complement: e.target.value}) } 
                                placeholder="digite aqui o complemento..." />
                        </div>
                    </div>

                    {/* Endereço - 2 */}
                    <div className="input-group-section" style={{ margin: 0, padding: 10, display: 'flex', justifyContent: 'space-between'}}>
                        {/* local_neighborhood */}
                        <div className="common-group-item" style={{ width: '70%'}}>
                            <label className="label-section">bairro</label>
                            <input 
                                type="text"
                                maxLength={50}
                                disabled={!this.state.isPresential}
                                value={this.state.local_neighborhood}
                                onChange={e => this.setState({ local_neighborhood: e.target.value}) }
                                placeholder="digite aqui o bairro..." />
                        </div>
                        {/* local_zipcode */}
                        <div className="common-group-item" style={{ width: '25%'}}>
                            <label className="label-section">cep</label>
                            <input 
                                type="text"
                                maxLength={9}
                                disabled={!this.state.isPresential}
                                value={this.state.local_zipcode}
                                onChange={e => this.setState({ local_zipcode: e.target.value}) }
                                placeholder="digite aqui a cep..." />
                        </div>
                    </div>

                    {/* Endereço - 3*/}
                    <div className="input-group-section" style={{ margin: 0, padding: 10, display: 'flex', justifyContent: 'space-between'}}>
                        {/* local_city */}
                        <div className="common-group-item" style={{ width: '60%'}}>
                            <label className="label-section">cidade</label>
                            <input 
                                type="text"
                                maxLength={80}
                                disabled={!this.state.isPresential}
                                value={this.state.local_city}
                                onChange={e => this.setState({ local_city: e.target.value}) }
                                placeholder="digite aqui a cidade..." />
                        </div>
                        {/* local_state */}
                        <div className="common-group-item" style={{ width: '10%'}}>
                            <label className="label-section">estado</label>
                            <input 
                                type="text"
                                maxLength={2}
                                disabled={!this.state.isPresential}
                                value={this.state.local_state}
                                onChange={e => this.setState({ local_state: e.target.value}) } 
                            />
                        </div>
                        {/* complement */}
                        <div className="common-group-item" style={{ width: '20%'}}>
                            <label className="label-section">pais</label>
                            <input 
                                type="text"
                                maxLength={50}
                                disabled={!this.state.isPresential}
                                value={this.state.local_country}
                                onChange={e => this.setState({ local_country: e.target.value}) }
                                placeholder="digite aqui o país..." />
                        </div>
                    </div>
                </div>

                {/* Cover picture */}
                <div className="input-group-section input-photo-section">
                    <label className="label-section">foto de capa (proporção 4:3, larg. 600px x alt. 200px, tipo jpg ou png)</label>
                    <div 
                        className="photo-preview"
                        style={{backgroundImage: `url(${this.state.image})`}} />
                    <input 
                        type="file"
                        name="edit-photo"
                        id="edit-photo"
                        onChange={this.handleSelectedFile} />
                    <label className="label-input" htmlFor="edit-photo">BUSCAR</label>
                </div>                

                <div className="input-group-section input-photo-section">
                    <label className="label-section">fotos do evento (proporção 4:3, largura máxima 600px, tipo jpg ou png)</label>

                    <div style={{width:'100%', display:'inline-block', marginTop: 25, backgroundColor:'#bbb'}}>
                        {this.renderImages()}
                    </div>
                    <input 
                        type="file"
                        name="edit-photo2"
                        id="edit-photo2"
                        onChange={this.handleSelectedImgFile} />
                    <label className="label-input" htmlFor="edit-photo2">BUSCAR</label>
                </div>
                

                {/* Save */}
                <div className="button-container">
                    <Button 
                        onClick={this.handleEventSave}
                        style={{ marginLeft: 'auto', marginRight: 'auto' }}
                        type="orange"
                        title={this.state.id ? "Salvar alterações" : "Criar evento"}
                    />
                    { this.renderRedirect() }
                </div>

            </div>
        );
    };



    async fetchData(id) {
        id = parseInt(id);
        this.setState({ loading: true });

        let event = await Api.eventPanelView(id);

        event = event.events;
        if (Utils.checkForErrors(this, event)) { return ; } 
        
        event = event.data.panel_view;
        if (Utils.checkForErrors(this, event)) { return; }

        event = event.data;
        this.setState({
            id: event.id,
            name: event.name,
            description: event.description,
            description_short: event.description_short,
            image: event.image,
            date_period: event.date_period,
            lot_price_1: event.lot_price_1,
            lot_date_1: (event.lot_date_1 ? new Date(event.lot_date_1) : null),
            lot_price_2: event.lot_price_2,
            lot_date_2: (event.lot_date_2 ? new Date(event.lot_date_2) : null),
            lot_price_3: event.lot_price_3,
            lot_date_3: (event.lot_date_3 ? new Date(event.lot_date_3) : null),
            local_name: event.local_name, 
            local_street: event.local_street, 
            local_number: event.local_number, 
            local_complement: event.local_complement, 
            local_neighborhood: event.local_neighborhood, 
            local_city: event.local_city, 
            local_state: event.local_state, 
            local_zipcode: event.local_zipcode, 
            local_country: event.local_country,
            images: event.images,
            link: event.link,
            isLink: (event.link != null && event.link != ''),
            isPresential: (event.local_street != null && event.local_street != '')
        }, () => {
            this.setState({ loading: false })
        });
    };

    fetchListElements() {                

        let allEvents = Api.eventsPanelList('', '', '', 1, 200, '');
        allEvents.then(data => {

            if (data.events.error_code === 0) {
                if (data.events.data.panel_list.error_code === 0) {
                    this.setState({ allEvents: data.events.data.panel_list.data.list });
                }
            }

        });
    }


    // ANCHOR
    handleSelectedFile = (event) => {
        
        let file = event.target.files[0];
        let config = {
            bucketName: 'mri-ead',
            bucketRoot: 'https://s3-sa-east-1.amazonaws.com/mri-ead/',
            region: 'sa-east-1',
            accessKeyId: 'AKIAIBBX5O7CKQWW22HA',
            secretAccessKey: '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',
            dirName: 'images'
        };

        // TODO: LOADER SPINNER
        uploadFile(file, config, API_ROOT)
            .then(data => {
                this.setState({ image: data.location });
                toast.success('Imagem enviada com sucesso!', {
                    className: 'toast-success'
                });
            }, function() {
                toast.error(`Erro subindo a imagem! Tente novamente!`, {
                    className: 'toast-error'
                });
            });
    
    };

    handleSelectedImgFile = (event) => {
        
        let file = event.target.files[0];
        let config = {
            bucketName: 'mri-ead',
            bucketRoot: 'https://s3-sa-east-1.amazonaws.com/mri-ead/',
            region: 'sa-east-1',
            accessKeyId: 'AKIAIBBX5O7CKQWW22HA',
            secretAccessKey: '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',
            dirName: 'images'
        };

        // TODO: LOADER SPINNER
        uploadFile(file, config, API_ROOT)
            .then(data => {
                if(this.state.images) {
                    this.setState({ images: [...this.state.images, data.location] });
                } else {
                    this.setState({ images: [data.location] });
                }

                toast.success('Imagem enviada com sucesso!', {
                    className: 'toast-success'
                });
            }, function() {
                toast.error(`Erro subindo a imagem! Tente novamente!`, {
                    className: 'toast-error'
                });
            });
    
    };

    handleEventSave = async () => {

        const { id, name, 
                description, description_short, image, date_period, 
                lot_price_1, lot_date_1, lot_price_2, lot_date_2, lot_price_3, lot_date_3,
                local_name, local_street, local_number, local_complement, local_neighborhood, local_city, 
                local_state, local_zipcode, local_country, images, link, isLink, isPresential
                } = this.state;


        // Validações dos campos obrigatórios para salvar
        if (!this.validateRequiredFields(name, description_short, date_period, lot_price_1, lot_date_1, lot_price_2, lot_date_2, lot_price_3, lot_date_3)) {
            return;
        }

        let vlink = (isLink ? link : '');
        let vlocal_name = local_name; 
        let vlocal_street = local_street; 
        let vlocal_number = local_number;
        let vlocal_complement = local_complement; 
        let vlocal_neighborhood = local_neighborhood;
        let vlocal_city = local_city; 
        let vlocal_state = local_state;
        let vlocal_zipcode = local_zipcode;
        let vlocal_country = local_country;
        if (!isPresential) {

            vlocal_name = '';
            vlocal_street = '';
            vlocal_number = 0;
            vlocal_complement = '';
            vlocal_neighborhood = '';
            vlocal_city = ''; 
            vlocal_state = '';
            vlocal_zipcode = '';
            vlocal_country = '';
        } 

        let upload = 
            await Api.eventsPanelEditor(id, name, description, description_short, image, date_period, 
                vlocal_name, vlocal_street, vlocal_number, vlocal_complement, vlocal_neighborhood, vlocal_city, 
                vlocal_state, vlocal_zipcode, vlocal_country, images, vlink, lot_price_1, lot_date_1, lot_price_2, lot_date_2, lot_price_3, lot_date_3
            );
        
        // Connection error
        upload = upload.events;
        if (Utils.checkForErrors(this, upload)) { return; }

        upload = upload.data.panel_editor;
        if (Utils.checkForErrors(this, upload)) { return; }

        upload = upload.data;
        if (Utils.checkForErrors(this, upload)) { return; }

        toast.success('Evento salvo com sucesso!', {
            className: 'toast-success'
        });
            
        if (id && id !== 0) { return; }
        this.setState({ id: upload.id },
            () => { this.setState({ redirect: true }) });
        
    }

    handleEventImgDel = async (index) => {

        var newDs = this.state.images.slice();
        
        // Remove o item da lista de posts
        newDs.splice(index, 1);

        this.setState({ images: newDs });
    }

    validateRequiredFields(name, description_short, date_period, lot_price_1, lot_date_1, lot_price_2, lot_date_2, lot_price_3, lot_date_3) {

        let requiredFieldsOK = true;
        if ((name == '') || (description_short == '') || (date_period == '') || (lot_price_1 == '') || (Number(lot_price_1) > 99999)) {
            
            toast.info('Verifique os campos obrigatórios com (*) para poder salvar a edição do evento.', {
                className: 'toast-info'
            });

            requiredFieldsOK = false;
        }

        if (((lot_price_2 != '') && (Number(lot_price_2) > 99999)) || 
            ((lot_price_3 != '') && (Number(lot_price_3) > 99999)) ||
            ((lot_date_1) && (lot_date_2) && (lot_date_1 > lot_date_2)) ||
            ((lot_date_2) && (lot_date_3) && (lot_date_2 > lot_date_3)) ||
            ((lot_date_2) && (lot_price_2 == '')) ||
            ((lot_date_3) && (lot_price_3 == '')) ||
            ((lot_price_2 != '') && (!lot_date_2)) ||
            ((lot_price_3 != '') && (!lot_date_3)) ||
            ((!lot_date_1) && (lot_date_2)) ||
            ((!lot_date_2) && (lot_date_3)) ||
            ((!lot_date_1) && (lot_date_3))
            ) {

                toast.info('Verifique as informações nas categorias de preços para poder salvar a edição do evento.', {
                    className: 'toast-info'
                });
    
                requiredFieldsOK = false;
            }

        return requiredFieldsOK;
    }

    renderRedirect() {
        // /editor?id=${this.state.id}
        if (this.state.redirect) {
            return <Redirect to={{ pathname: `/eventos`, state: { from: this.props.location } }} />
        }
    }

    renderImages() {
        if ((this.state.images == null) || (this.state.images.length === 0)) { 
            return (
                <p style={{ fontFamily: 'Averta', fontSize: '1.2rem' }}>Não há imagens adicionais!</p>
            ); 
        }

        return this.state.images.map((item, index) => {
            return (
                <div style={{display:'inline-block', padding: 10}} key={index}>
                    <img src={item} width={150} alt='' />

                    <Button 
                        onClick={() => this.handleEventImgDel(index)}
                        style={{ marginLeft: 'auto', marginRight: 'auto', paddingTop: 2, paddingBottom: 2, height: 20 }}
                        type="orange"
                        title={"Excluir"}
                    />
                </div>
                
                )}
        );
    };
};


export default ScreenEventEdit;