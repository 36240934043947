import '../css/app.css';
import React from 'react';
import Api from '../../apis/api';
import Utils from '../Utils';
import { uploadFile } from 'react-s3';
import { API_ROOT } from '../../config';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import Button from '../common/Button';
import TextareaAutosize from 'react-autosize-textarea';
import iconeSpin from '../../assets/images/icone_spin.png';

import { toast } from 'react-toastify';


class PlanItem extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showEdit: false,
            showDelete: false,

            id: '',
            price: '',
            spinsCount: '',

            inputPrice: '',
            inputSpinsCount: ''
        };
    };

    componentDidMount = (props) => {
        const { item } = this.props;
        
        this.setState({
            id: item.id,

            price: item.price,
            spinsCount: item.spins_count,

            inputPrice: item.price,
            inputSpinsCount: item.spins_count
        });
    };

    render() {
        const { price, spinsCount } = this.state;

        return (
            <div>
                <table style={{width: '100%'}}>
                    <tbody>
                        <tr>
                            <td style={{width: '30%'}} />
                            <td style={{width: '10%'}}>
                                <img src={iconeSpin} alt="Spin" width={60} />
                            </td>
                            <td style={{width: '10%'}}>
                                <h3 className="name">{spinsCount}</h3>
                            </td>
                            <td style={{width: '10%'}}>
                                <h3 className="name">R$ {price}</h3>
                            </td>
                            <td style={{width: '10%'}}>
                                <div className="actions">
                                    <div className="edit-icon" onClick={this.handleShowEdit} style={{cursor:'pointer'}}>
                                        <FontAwesomeIcon icon={'cog'} />
                                    </div>
                                    <div className="edit-icon" onClick={this.handleShowDelete} style={{cursor:'pointer'}}>
                                        <FontAwesomeIcon icon={'trash'} />
                                    </div>
                                </div>
                            </td>
                            <td style={{width: '30%'}} />
                        </tr>
                    </tbody>
                </table>

                <Modal 
                    show={this.state.showEdit}
                    onHide={this.handleCloseEdit}
                    backdrop="static"
                    className="modal">
                    <Modal.Header closeButton>
                        <Modal.Title className="modal-title">Editar Plano</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="modal-card-data">
                            <div className="modal-input-group modal-input-name">
                                <label>spins(*)</label>
                                <input 
                                    type="text"
                                    value={this.state.inputSpinsCount}
                                    onChange={e => this.setState({ inputSpinsCount: e.target.value}) }
                                    placeholder="digite aqui a quantidade de spins..." />
                            </div>
                            <div className="modal-input-group modal-input-name">
                                <label>valor (R$) (*)</label>
                                <input
                                    type="text"
                                    value={this.state.inputPrice}
                                    onChange={e => this.setState({ inputPrice: e.target.value}) }
                                    placeholder="digite aqui o valor..." />
                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={this.handleSave} type="orange" title="Salvar" />
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showDelete}
                    onHide={this.handleCloseDelete}
                    backdrop="static"
                    className="modal">
                    <Modal.Header closeButton>
                        <Modal.Title className="modal-title">Deletar Plano</Modal.Title>
                    </Modal.Header>

                    <Modal.Body style={{ minHeight: '100px', textAlign: 'center' }}>
                        <h3>O item deletado não poderá ser recuperado. Você deseja continuar?</h3>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            style={{ width: '100px' }}
                            onClick={this.handleDelete}
                            type="orange"
                            title="Sim" />
                        <Button 
                            style={{ width: '100px' }}
                            onClick={this.handleCloseDelete}
                            title="Não" />
                    </Modal.Footer>
                </Modal>
            </div>
        );
    };



    // Handlers
    handleCloseEdit = () => {
        this.setState({ 
            showEdit: false
        });
    };

    handleShowEdit = () => {
        this.setState({ inputPrice: this.state.price,
                        inputSpinsCount: this.state.spinsCount
                    }, () => this.setState({ showEdit: true }));
        ;
    };

    handleCloseDelete = () => {
        this.setState({ showDelete: false });
    };

    handleShowDelete = () => {
        this.setState({ showDelete: true });
    };

    handleDelete = () => {
        this.props.onPlanDelete(this.state.id);
        this.handleCloseDelete();
    };

    handleSave = () => {

        let price = Number(this.state.inputPrice.toString().replace(',', '.'));
        let spinsCount = this.state.inputSpinsCount;

        // Validações dos campos obrigatórios para salvar
        if (!this.validateRequiredFields(price, spinsCount)) {
            return;
        }

        this.setState({ showEdit: false });

        this.props.onSaveChanges(this.state.id, this.state.inputPrice, this.state.inputSpinsCount);
        this.handleCloseDelete();
    };

    validateRequiredFields(price, spinsCount) {

        let requiredFieldsOK = true;
        if ((price == '') || (price == 0) || (spinsCount == '') || (spinsCount == 0) || isNaN(price) || isNaN(spinsCount) || (Number(price) > 99999) || (Number(spinsCount) > 99999)) {
            
            toast.info('Verifique os campos obrigatórios com (*) para poder salvar a edição do plano.', {
                className: 'toast-info'
            });

            requiredFieldsOK = false;
        }

        return requiredFieldsOK;
    }
}


export default PlanItem;