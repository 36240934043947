import React from 'react';
import HeaderItem from '../common/HeaderItem';



// const Header = (props) => {
//     if (!props.logged) { return null; }
    
//     return (
//         <div id="header">
//             <div className="header-abs-item logo left">
//                 <HeaderItem path="/" alt="MRI" />
//             </div>
//             <div className="header-menu">
//                 <HeaderItem path="/cursos" title="cursos" />
//                 <HeaderItem path="/professores" title="professores" />
//                 <HeaderItem path="/selos" title="selos" />
//                 <HeaderItem path="/patrocinadores" title="patrocinadores" />
//                 {/* <HeaderItem path="/estatisticas" title="estatísticas" /> */}
//             </div>
//             <div className="header-abs-item header-menu right">
//                 <div 
//                     onClick={props.onUserLogout}
//                     className="header-item">Sair</div>
//             </div>
//         </div>
//     );
    
// }

class Header extends React.Component {

    render() {
        //console.log(this.props);
        if (!this.props.logged) { return null; }
    
        return (
            <div id="header">
                <div className="header-abs-item logo left">
                    <HeaderItem path="/" alt="MRI" />
                </div>
                <div className="header-menu">
                    <HeaderItem path="/cursos" title="cursos" />
                    <HeaderItem path="/eventos" title="eventos" />
                    <HeaderItem path="/professores" title="professores" />
                    <HeaderItem path="/selos" title="selos" />
                    <HeaderItem path="/patrocinadores" title="patrocinadores" />
                    <HeaderItem path="/usuários" title="usuários" />
                    <HeaderItem path="/planos" title="planos" />
                    <HeaderItem path="/estatisticas" title="estatísticas" />
                    <HeaderItem path="/financeiro" title="financeiro" />
                </div>
                <div className="header-abs-item header-menu right">
                    <div 
                        onClick={this.props.onUserLogout}
                        className="header-item">Sair</div>
                </div>
            </div>
        );
    }
    
}



export default Header;