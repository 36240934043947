import React from 'react';
import Api from '../apis/api';
import Utils from '../components/Utils';
import { uploadFile } from 'react-s3';
import { API_ROOT } from '../config';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import Button from '../components/common/Button';
import UserItem from '../components/js/UserItem';
import TextareaAutosize from 'react-autosize-textarea';

import { toast } from 'react-toastify';
import { Row } from 'react-bootstrap';


class ScreenUsersList extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            loading: false,

            users: [],
            page: 1,

            error: 0,
            numberOfPages: undefined,
            buttonShowMore: true,
        };
    }

    componentDidMount() {
        this.fetchData(1, 20);
    };
    
    fetchData(page, pageSize = 20) {
        this.setState({ loading: true },
            async () => {

                let users = await Api.userList(page, pageSize);
                console.log("users => ",users)
                if(users.users.list.length < 20) {
                    this.setState({buttonShowMore: false})
                }
                users = users.users;
                if (Utils.checkForErrors(this, users)) { return; };
                
                users = users.list;
                if (Utils.checkForErrors(this, users)) { return; };
        
                this.setState(prevState => {
                    console.log(prevState)
                    return {users: [...prevState.users, ...users]}
                }, () => this.setState({ loading: false }));
            });
    }

    renderList() {
        if (this.state.users.length === 0) { return null; }

        return this.state.users.map((user) => {
            return (
                <UserItem
                    onSaveChanges={this.saveChanges}
                    key={user.id}
                    item={user}
                />
            ); 
        });
    };

    render() {
        if (this.state.error !== 0) {
            return null;
        }

        return (
            <div className="main-content" style={{ textAlign: "center" }}>
                <div id="plans-list">
                    <table style={{width: '100%'}}>
                        <tbody>
                            <tr>
                                <td style={{width: '30%'}}>
                                    <h3 className="name">Nome</h3>
                                </td>
                                <td style={{width: '30%'}}>
                                    <h3 className="name">E-mail</h3>
                                </td>
                                <td style={{width: '20%'}}>
                                    <h3 className="name">Telefone</h3>
                                </td>
                                <td style={{width: '10%'}}>
                                    <h3 className="name">Spins</h3>
                                </td >
                                <td style={{width: '10%'}}>
                                    <h3 className="name" >+Spin</h3>
                                </td >
                            </tr>
                        </tbody>
                    </table>

                    <hr style={{height:1, width: '95%'}} />

                    {this.renderList()}

                    {this.state.buttonShowMore && <Row style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center'
                    }}>
                        <Button
                            onClick={() => this.fetchData(this.state.page + 1, 20)}
                            // style={{ width: '140px' }}
                            // icon={this.state.published ? 'arrow-down' : 'arrow-up'}
                            type="orange"
                            title={'Ver mais'} />
                    </Row>}
                </div>
            </div>
        );
    };

    saveChanges = async (value, idUser) => {
        
        let upload = await Api.marketAdd(value, idUser);

        upload = upload.market;
        if (Utils.checkForErrors(this, upload)) { return; }

        upload = upload.data.add;
        if (Utils.checkForErrors(this, upload)) { return; }

        upload = upload.data;
        if (Utils.checkForErrors(this, upload)) { return; }


        toast.success('Crédito adicionado com sucesso!', {
            className: 'toast-success'
        });

        this.fetchData(1, 200);
    }

};


export default ScreenUsersList;