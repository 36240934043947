import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';

import Button from '../common/Button';
import QuestionItem from './QuestionItem';


class EvaluationItem extends React.Component {

    // Lifecycle
    constructor(props) {
        super(props);

        this.state = {
            showEdit: false, 
            showDelete: false,

            id: 0,
            index: '',
            name: '',
            elements: [],
            type: '',
            description: '',

            inputName: '',
            inputDescription: '',
            inputElements: []
        };
    };

    componentDidMount = () => {const { item, index } = this.props;

        if (!item) { return; }
        
        this.setState({
            id: item.id,
            index: index,
            name: item.name,
            elements: item.elements,
            type: item.type,
            description: item.description,
            
            inputName: item.name,
            inputDescription: item.description,
            inputElements: item.elements
        });

    };



    renderChangedIcon() {
    //     if (this.props.hasChanged) {
    //         return (
    //             <div className="changed-icon">
    //                 <FontAwesomeIcon icon={'exclamation-circle'} />
    //             </div>
    //         );
    //     }

    //     return null;
    }

    render() {
        const { name, description } = this.state;

        let evaluationClassName = 'evaluation-item';
        // if (this.props.hasChanged) {
        //     evaluationClassName += ' changed';
        // }

        return (
            <div className={evaluationClassName}>
                {this.renderChangedIcon()}
                <div>
                    <span className="label">{this.props.label}</span><h5 className="name">{name ? name : 'Pré-teste'}</h5>
                    <p className="description">{description ? description : 'Exemplo de descrição'}</p>
                </div>
                <div className="actions">
                    <div className="edit-icon" onClick={this.handleShowEdit}>
                        <FontAwesomeIcon icon={'cog'} />Editar
                    </div>
                    <div className="edit-icon" onClick={this.handleShowDelete}>
                        <FontAwesomeIcon icon={'trash'} />Deletar
                    </div>
                </div>

                <Modal
                    show={this.state.showEdit}
                    onHide={this.handleCloseEdit}
                    backdrop="static"
                    dialogClassName="modal-dialog-90w"
                    className="modal">
                    <Modal.Header closeButton>
                        <Modal.Title className="modal-title">Editar Avaliação</Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="form" style={{ paddingBottom: 0 }}>
                        <div className="input-group-section" style={{ justifyContent: 'space-between', height: '50px' }}>
                            {/* Name */}
                            <div className="common-group-item" style={{ width: '39%'}}>
                                <label className="label-section">nome (*)</label>
                                <textarea 
                                    rows={1}
                                    value={this.state.inputName}
                                    onChange={e => this.setState({ inputName: e.target.value }) }
                                    placeholder="digite aqui um nome p/ a avaliação..." />
                            </div>
                            {/* Description */}
                            <div className="common-group-item" style={{ width: '59%'}}>
                                <label className="label-section">descrição (*)</label>
                                <textarea 
                                    rows={1}
                                    value={this.state.inputDescription}
                                    onChange={e => this.setState({ inputDescription: e.target.value }) }
                                    placeholder="digite aqui uma descrição p/ a avaliação..." />
                            </div>
                        </div>

                        {/* Elements */}
                        <div 
                            className="input-group-section input-list-section"
                            style={{ 
                                height: 'calc(100% - 66px)', 
                                alignItems: 'center', 
                                overflowX: 'auto', 
                                overflowY: 'hidden', 
                                marginBottom: 0,
                                padding: '0.5rem'
                            }}>
                            <ul 
                                className="input-items-section" 
                                style={{ 
                                    display: 'flex', 
                                    flexDirection: 'row', 
                                    justifyContent: 'flex-start', 
                                    marginTop: 0, marginBottom: 0, 
                                    height: '100%'
                                }}>
                                {this.renderQuestionItems()}
                            </ul>
                            <Button 
                                onClick={() => {
                                    let newQuestion = {
                                        type: 1,
                                        question: '',
                                        options: ['', ''],
                                        answer: 0
                                    }

                                    this.setState({ inputElements: [...this.state.inputElements, newQuestion] });
                                    //() => console.log(this.state));
                                }}
                                class="add-question"
                                type="orange"
                                icon={'plus'}
                                />
                            
                        </div>

                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={this.saveQuestionChanges} type="orange" title="Salvar" />
                    </Modal.Footer>
                </Modal>
            
                <Modal
                    show={this.state.showDelete}
                    onHide={this.handleCloseDelete}
                    backdrop="static"
                    className="modal">
                    <Modal.Header closeButton>
                        <Modal.Title className="modal-title">Deletar Avaliação</Modal.Title>
                    </Modal.Header>

                    <Modal.Body style={{ minHeight: '100px', textAlign: 'center' }}>
                        <h3>A avaliação deletada não poderá ser recuperada. Você deseja continuar?</h3>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={this.handleDelete} type="orange" title="Sim" />
                        <Button onClick={this.handleCloseDelete} title="Não" />
                    </Modal.Footer>
                </Modal>
            </div>
        );
    };



    // Handlers
    handleCloseEdit = () => {
        this.setState({ 
            showEdit: false
        });
    };

    handleShowEdit = () => {

        this.setState({ 
            inputName: this.state.name,
            inputDescription: this.state.description,
            inputElements: this.state.elements
        }, () => this.setState({ showEdit: true }));
        
    };

    handleCloseDelete = () => {
        this.setState({ 
            showDelete: false
        });
    };

    handleShowDelete = () => {
        this.setState({ showDelete: true });
    };

    handleDeleteQuestion = (index) => {
        this.setState({inputElements: [...this.state.inputElements.slice(0, index), ...this.state.inputElements.slice(index + 1)] } );
    }

    handleDelete = () => {
        this.props.onEvaluationDelete(this.state.index);
        this.handleCloseDelete();
    }

    handleQuestionEdit = (index, type, value, optional=null, action=null) => {

        let element = this.state.inputElements[index];
        
        switch(type) {
            case 'question' :
                element.question = value;
            break;
            case 'options' :
                if (action === 'edit') {
                    element.options[optional] = value;
                } else if (action === 'add') {
                    element.options = [...element.options, value];
                } else if (action === 'remove') {
                    element.options = [...element.options.slice(0, optional), ...element.options.slice(optional + 1)];
                }
            break;
            case 'answer' :
                element.answer = value;
            break;
            default :
            break;
        }

        let inputElements = this.state.inputElements.map((opt, i) => {
            if (i === index) { return element; }
            else { return opt; }
        });

        this.setState({ inputElements: inputElements });
    }



    renderQuestionItems = () => {
        let list = this.state.inputElements.map((element, index) => {
            return (
                <QuestionItem
                    key={index}
                    item={element} 
                    index={index}
                    onDeleteQuestion={this.handleDeleteQuestion}
                    onQuestionEdit={this.handleQuestionEdit}
                    />
            );
        });

        return list;
    }



    // Save and Load
    saveQuestionChanges = async () => {
        let name = this.state.inputName;
        let description = this.state.inputDescription;
        let elements = this.state.inputElements;

        this.setState({
            name: name, 
            description: description,
            elements: elements
        }, () => {
            this.props.onEvaluationEdit(this.state.index, name, description, elements);
            this.setState({ showEdit: false });
        })
    }
}



export default EvaluationItem;