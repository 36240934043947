import '../css/app.css';
import React from 'react';
import Api from '../../apis/api';
import Utils from '../Utils';
import { uploadFile } from 'react-s3';
import { API_ROOT } from '../../config';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import Button from '../common/Button';
import TextareaAutosize from 'react-autosize-textarea';

import { toast } from 'react-toastify';


class TeacherItem extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showEdit: false,
            showDelete: false,

            id: '',
            image: '',
            name: '',
            lastName: '',
            formation: [],

            inputImage: '',
            inputName: '',
            inputLastName: '',
            inputFormation: '',
            inputFormationList: []
        };
    };

    componentDidMount = (props) => {
        const { item } = this.props;
        
        this.setState({
            id: item.id,

            image: item.image,
            name: item.name,
            lastName: item.lastname,
            formation: item.formation,

            inputImage: item.image,
            inputName: item.name,
            inputLastName: item.lastname,
            inputFormation: '',
            inputFormationList: item.formation,
        });
    };

    render() {
        const { image, name, lastName } = this.state;

        return (
            <div className="teacher-item">
                <div
                    className="avatar-container"
                    style={{backgroundImage: `url(${image})`}}
                />
                <h3 className="name">{name} {lastName}</h3>
                <ul className="list">{this.renderFormationItemsList()}</ul>
                <div className="actions">
                    <div className="edit-icon" onClick={this.handleShowEdit}>
                        <FontAwesomeIcon icon={'cog'} />
                    </div>
                    <div className="edit-icon" onClick={this.handleShowDelete}>
                        <FontAwesomeIcon icon={'trash'} />
                    </div>
                </div>

                <Modal 
                    show={this.state.showEdit}
                    onHide={this.handleCloseEdit}
                    backdrop="static"
                    className="modal">
                    <Modal.Header closeButton>
                        <Modal.Title className="modal-title">Editar Professor</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div
                            className="modal-avatar-container"
                            style={{backgroundImage: `url(${this.state.inputImage})`}}>
                            <input
                                type="file"
                                name="edit-avatar"
                                id="edit-avatar"
                                onChange={this.handleSelectedFile} />
                            <label htmlFor="edit-avatar">buscar</label>
                        </div>
                        <div className="modal-card-data">
                            <div className="modal-input-group modal-input-name">
                                <label>nome (*)</label>
                                <input 
                                    type="text"
                                    value={this.state.inputName}
                                    onChange={e => this.setState({ inputName: e.target.value}) }
                                    placeholder="digite aqui o nome..." />
                            </div>
                            <div className="modal-input-group modal-input-name">
                                <label>sobrenome (*)</label>
                                <input
                                    type="text"
                                    value={this.state.inputLastName}
                                    onChange={e => this.setState({ inputLastName: e.target.value}) }
                                    placeholder="digite aqui o sobrenome..." />
                            </div>
                            <div className="modal-input-group modal-input-formation">
                                <label>formação</label>
                                <TextareaAutosize 
                                    value={this.state.inputFormation}
                                    onChange={e => this.setState({ inputFormation: e.target.value }) }
                                    onKeyPress={this.handleArrayAdd}
                                    placeholder="digite aqui uma formação e pressione enter..."
                                    async />
                                <ul className="modal-input-items">
                                    {this.renderFormationItemsView()}
                                </ul>
                            </div>
                        </div>
                    </Modal.Body>

                    <div style={{textAlign:'center'}}>
                        <label className="style-button" htmlFor="insert-picture"><FontAwesomeIcon icon='image' /> obs.: imagem (proporção 1:1, largura máxima 600px, tipo jpg ou png)</label>
                    </div>

                    <Modal.Footer>
                        <Button onClick={this.saveChanges} type="orange" title="Salvar" />
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showDelete}
                    onHide={this.handleCloseDelete}
                    backdrop="static"
                    className="modal">
                    <Modal.Header closeButton>
                        <Modal.Title className="modal-title">Deletar Professor</Modal.Title>
                    </Modal.Header>

                    <Modal.Body style={{ minHeight: '100px', textAlign: 'center' }}>
                        <h3>O item deletado não poderá ser recuperado. Você deseja continuar?</h3>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            style={{ width: '100px' }}
                            onClick={this.handleDelete}
                            type="orange"
                            title="Sim" />
                        <Button 
                            style={{ width: '100px' }}
                            onClick={this.handleCloseDelete}
                            title="Não" />
                    </Modal.Footer>
                </Modal>
            </div>
        );
    };



    // Handlers
    handleCloseEdit = () => {
        this.setState({ 
            showEdit: false
        });
    };

    handleShowEdit = () => {
        this.setState({ inputName: this.state.name,
                        inputLastName: this.state.lastName,
                        inputImage: this.state.image,
                        inputFormation: '',
                        inputFormationList: this.state.formation,
                    }, () => this.setState({ showEdit: true }));
        ;
    };

    handleCloseDelete = () => {
        this.setState({ showDelete: false });
    };

    handleShowDelete = () => {
        this.setState({ showDelete: true });
    };

    handleDelete = () => {
        this.props.onTeacherDelete(this.state.id);
        this.handleCloseDelete();
    };

    handleSelectedFile = (event) => {
        
        let file = event.target.files[0];
        let config = {
            bucketName: 'mri-ead',
            bucketRoot: 'https://s3-sa-east-1.amazonaws.com/mri-ead/',
            region: 'sa-east-1',
            accessKeyId: 'AKIAIBBX5O7CKQWW22HA',
            secretAccessKey: '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',
            dirName: 'images'
        };

        // TODO: LOADER SPINNER
        uploadFile(file, config, API_ROOT)
            .then(data => {
                this.setState({ inputImage: data.location });
                toast.success('Imagem enviada com sucesso!', {
                    className: 'toast-success'
                });
            }, function() {
                toast.error(`Erro subindo a imagem! Tente novamente!`, {
                    className: 'toast-error'
                });
            });
    
    };

    handleArrayAdd = (event) => {
        if (event.key !== 'Enter') { return; }
        event.preventDefault();

        if (this.state.inputFormation !== '') {
            this.setState(
                { inputFormationList: [...this.state.inputFormationList, Utils.capitalizeFirstLetter(this.state.inputFormation)]},
                () => this.setState({ inputFormation: '' }));
        }
    };

    handleArrayRemove(index) {
        this.setState(
            { inputFormationList: [...this.state.inputFormationList.slice(0, index), ...this.state.inputFormationList.slice(index + 1)] }
        );
    }



    renderFormationItemsList = () => {

        const list = this.state.formation.map((formation, index) => {
            let text = Utils.capitalizeFirstLetter(formation);
            return (
                <li key={index} className="formation-item">{ text }</li>
            );
        });
        return list;
    }

    renderFormationItemsView = () => {
        const list = this.state.inputFormationList.map((formation, index) => {
            let text = Utils.capitalizeFirstLetter(formation);
            return (
                <li key={index} className="modal-input-item">{ text }<FontAwesomeIcon onClick={() => this.handleArrayRemove(index)} icon={'times'} /></li>
            );
        });

        return list;
    }


    // Save
    saveChanges = async () => {
        let id = this.state.id;
        let name = this.state.inputName;
        let lastName = this.state.inputLastName;
        let formation = this.state.inputFormationList;
        let image = this.state.inputImage;

        // Validações dos campos obrigatórios para salvar
        if (!this.validateRequiredFields(name, lastName)) {
            return;
        }

        let upload = await Api.teacherPanelEditor(id, name, lastName, formation, image);


        upload = upload.teachers;
        if (Utils.checkForErrors(this, upload)) { return; }
        //console.log(upload);

        upload = upload.data.panel_editor;
        if (Utils.checkForErrors(this, upload)) { return; }
        //console.log(upload);

        upload = upload.data;
        if (Utils.checkForErrors(this, upload)) { return; }
        else {
            this.setState({
                image: image,
                name: Utils.capitalizeFirstLetter(name),
                lastName: Utils.capitalizeFirstLetter(lastName),
                formation: this.state.inputFormationList,
            }, () => {
                toast.success('Professor cadastrado(a) com sucesso!', {
                    className: 'toast-success'
                });

                this.setState({ showEdit: false });
            });
        }
        
    }

    validateRequiredFields(name, lastName) {

        let requiredFieldsOK = true;
        if ((name == '') || (lastName == '')) {
            
            toast.info('Verifique os campos obrigatórios com (*) para poder salvar a edição do professor.', {
                className: 'toast-info'
            });

            requiredFieldsOK = false;
        }

        return requiredFieldsOK;
    }

    // checkForErrors(element) {
    //     if (element.error_code !== 0) {
    //         this.setState({ error: element.error_code });

    //         toast.error(`Erro executando esta operação! Código de erro: ${element.error_code}`, {
    //             className: 'toast-error'
    //         });
            
    //         return true;
    //     }

    //     return false;
    // };

}


export default TeacherItem;