import React from 'react';
import Api from '../apis/api';
import Utils from '../components/Utils';

import Button from '../components/common/Button';

import DatePicker,{ registerLocale, setDefaultLocale } from "react-datepicker";
import { pt } from 'date-fns/locale';
registerLocale('pt', pt);
setDefaultLocale('pt');


class ScreenStats extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: 0,

            stats: [],
            dateStart: null,
            dateEnd: null
        };

    }

    componentDidMount() {
        this.fetchData('', '');
    }

    async fetchData(dateStart, dateEnd) {
        this.setState({ loading: true });

        let stats = await Api.statsPanelList(dateStart, dateEnd);

        stats = stats.stats;
        if (Utils.checkForErrors(this, stats)) { return; };

        stats = stats.data.panel_list;
        if (Utils.checkForErrors(this, stats)) { return; };

        stats = stats.data;
        this.setState({ stats: stats }, () => this.setState({ loading: false }));
    }



    render() {
        return (
            <div className="main-content min-600 centered" style={{ textAlign: 'center' }}>
                { this.renderStats() }
            </div>
        );
    }

    renderStats() {
        return (
            <div id='stats'>
                <div className='stats-row form'>
                    <div className="input-group-section" style={{ width: '30%', margin: '1rem' }}>
                        <label className="label-section">de</label>
                        <DatePicker
                            selected={this.state.dateStart}
                            onChange={date => { this.handleChangeDate('start', date) }}
                            dateFormat="dd/MM/yyyy"
                        />
                    </div>
                    <div className="input-group-section" style={{ width: '30%', margin: '1rem' }}>
                        <label className="label-section">a</label>
                        <DatePicker
                            selected={this.state.dateEnd}
                            onChange={date => { this.handleChangeDate('end', date) }}
                            dateFormat="dd/MM/yyyy"
                        />
                    </div>
                    <Button
                        style={{ width: '30%' }}
                        onClick={() => {
                            let dateStart = 0, dateEnd = 0;
                            if (this.state.dateStart !== null) {
                                dateStart = this.state.dateStart.getTime() / 1000;
                            }
                            if (this.state.dateEnd !== null) {
                                dateEnd = this.state.dateEnd.getTime() / 1000;
                            }
                            this.fetchData(dateStart, dateEnd);
                        }}
                        type="orange"
                        title="Filtrar" />
                </div>
                {/* Courses */}
                <div className='stats-row'>
                    <div className='stat-card'>
                        <h1 className='title'>{this.state.stats.courses_total}</h1>
                        <p className='text'>cursos cadastrados</p>
                    </div>
                    <div className='stat-card'>
                        <h1 className='title'>{this.state.stats.courses_running}</h1>
                        <p className='text'>cursos em andamento</p>
                    </div>
                    <div className='stat-card'>
                        <h1 className='title'>{this.state.stats.courses_finished}</h1>
                        <p className='text'>cursos finalizados</p>
                    </div>
                </div>

                <div className='stats-row'>
                    <div className='stat-card'>
                        <h1 className='title'>{this.state.stats.market_transactions_courses_total}</h1>
                        <p className='text'>compras realizadas (cursos)</p>
                    </div>
                    <div className='stat-card'>
                        <h1 className='title'>{this.state.stats.students_total}</h1>
                        <p className='text'>alunos cadastrados</p>
                    </div>
                    <div className='stat-card'>
                        <h1 className='title'>{this.state.stats.students_active}</h1>
                        <p className='text'>alunos ativos</p>
                    </div>
                </div>

                <div className='stats-row'>
                    <div className='stat-card'>
                        <h1 className='title'>{Utils.reduceString(this.state.stats.market_transactions_spins_amount)}</h1>
                        <p className='text'>spins comprados</p>
                    </div>
                    <div className='stat-card'>
                        <h1 className='title'>{this.state.stats.bookmarks_total}</h1>
                        <p className='text'>nº de pins</p>
                    </div>
                    <div className='stat-card'>
                        <h1 className='title'>{this.state.stats.notebooks_total}</h1>
                        <p className='text'>nº de anotações</p>
                    </div>
                </div>

                <div className='stats-row'>
                    <div className='stat-card'>
                        <h1 className='title'>{this.state.stats.courses_finished}</h1>
                        <p className='text'>certificados emitidos</p>
                    </div>
                </div>
                
            </div>
            
        );
    }
    


    handleChangeDate = (type, date) => {
        if (type === 'start') {
            this.setState({ dateStart: date });
        } else {
            this.setState({ dateEnd: date });
        }
    }

};


export default ScreenStats;