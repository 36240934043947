import React from 'react';



const Line = (props) => {

    //console.log(props);
    let classStyle = 'table-line';
    if (props.classStyle) {
        classStyle += ` ${props.classStyle}`;
    }

    let fontstyle = 'normal';
    if (props.font && props.font === 'bold') {
        fontstyle = 'bold';
    }

    return (
        <div className={classStyle}>
            {props.data.map((cell, i) => {
                //cell.content = cell.content.replace();

                if (cell.size !== 0) {
                    return (
                        <div key={i} className='table-cell' style={{ width: `${cell.size}%` }}>
                            <span style={{ fontWeight: `${fontstyle}` }}>
                                {cell.content}
                            </span>
                            {i < props.data.length - 1 ? <div className="divider" /> : ''}
                        </div>
                    )
                } else {
                    return (
                        <div key={i} className='table-cell' style={{ flex: 1 }}>
                            <span style={{ fontWeight: `${fontstyle}` }}>
                                {cell.content}
                            </span>
                            {i !== props.data.length ? <div className="divider" /> : ''}
                        </div>
                    )
                }
            })}
        </div>
    );
}
// style={{ width: cell.size !== 0 ? `${cell.size}%` : 'auto',
//                                 flex: cell.size === 0 ? '1' : '0' }}>



export default Line;