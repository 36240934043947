import 'babel-polyfill';
import 'dotenv/config';
import React from 'react';
import ReactDOM from 'react-dom';

import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

import App from './components/App';


library.add(far, fas);


ReactDOM.render(<App />, document.getElementById('root'));

