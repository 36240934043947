import 'draft-js/dist/Draft.css';
import React from 'react';
import { uploadFile } from 'react-s3';
import { API_ROOT } from '../../config';

import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import TextareaAutosize from 'react-autosize-textarea';
import BlockUi from 'react-block-ui';

import Button from '../common/Button';

import {
    AtomicBlockUtils,
    CharacterMetadata,
    ContentBlock,
    convertToRaw,
    Editor, 
    EditorState, 
    genKey,
    RichUtils,
    Modifier,
    BlockMapBuilder } from 'draft-js';
import { List, Repeat } from 'immutable';
import { mediaBlockRenderer } from '../entities/mediaBlockRenderer';


// CSS
const styleMap = {
    CODE: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2,
    },
};


// Editor Classes and Functions
const BLOCK_TYPES = [
    {label: 'Título', style: 'header-two'},
    {label: 'Sub-título', style: 'header-four'},
    {label: 'Citação', style: 'blockquote'},
];

var INLINE_STYLES = [
    {label: 'Bold', style: 'BOLD'},
    {label: 'Italic', style: 'ITALIC'},
    {label: 'Underline', style: 'UNDERLINE'}
];


class StyleButton extends React.Component {
    constructor() {
        super();
        this.onToggle = (e) => {
            e.preventDefault();
            this.props.onToggle(this.props.style);
        };
    }

    render() {
        let className = 'style-button';
        if (this.props.active) {
            className += ' active-button';
        }

        return (
            <span className={className} onMouseDown={this.onToggle}>
                {this.props.icon ? <FontAwesomeIcon icon={this.props.icon} /> : null}{this.props.label}
            </span>
        );
    }
}


const BlockStyleControls = (props) => {
    const {editorState} = props;
    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

    return (
        <div className="controls-block">
            {BLOCK_TYPES.map((type) =>
            <StyleButton
                key={type.label}
                active={type.style === blockType}
                label={type.label}
                onToggle={props.onToggle}
                style={type.style} 
                icon={type.icon} />
            )}
        </div>
    );
};

const InlineStyleControls = (props) => {
    const currentStyle = props.editorState.getCurrentInlineStyle();
    
    return (
        <div className="controls-block">
            {INLINE_STYLES.map((type) =>
            <StyleButton
                key={type.label}
                active={currentStyle.has(type.style)}
                label={type.label}
                onToggle={props.onToggle}
                style={type.style} />
            )}
        </div>
    );
};

function getBlockStyle(block) {
    switch (block.getType()) {
        case 'blockquote': return 'blockquote';
        //case 'image': return 'atomic-image'
        default: return null;
    }
}



// Item with editor in a modal
class LessonItem extends React.Component {

    // Lifecycle
    constructor(props) {
        super(props);

        this.state = {
            showEdit: false, 
            showDelete: false,
            blocking: false, 

            id: '',
            index: '',
            name: '',
            elements: [],

            inputName: '',
            inputImage: '',
            inputVideo: '',

            editorState: EditorState.createEmpty()
        };

        this.focus = () => this.refs.editor.focus();
        this.onChange = (editorState) => this.setState({editorState});
    };

    componentDidMount = () => {

        const { item, index } = this.props;
        
        this.setState({
            id: item.id,
            index: index,
            name: item.name,
            elements: item.elements,
            
            inputName: item.name
        });


        if (item.id === 0) { return; }

        let editorLoadState = this.loadElements(item.elements);
        this.setState({ editorState: editorLoadState });
        
        // if (editorLoadState.length < 1) { return; }
        // return this.setState({
        //     editorState: EditorState.createWithContent(ContentState.createFromBlockArray(editorLoadState))
        // });

    };

    renderChangedIcon() {
        if (this.props.hasChanged) {
            return (
                <div className="changed-icon">
                    <FontAwesomeIcon icon={'exclamation-circle'} />
                </div>
            );
        }

        return null;
    }

    render() {
        const { index, name, editorState } = this.state;

        let editorClassName = 'editor';
        var contentState = editorState.getCurrentContent();
        if(!contentState.hasText()) {
            if (contentState.getBlockMap().first().getType() !== 'unstyled') {
                editorClassName += ' editor-hide-placeholder';
            }
        }

        let lessonClassName = 'lesson-item';
        if (this.props.hasChanged) {
            lessonClassName += ' changed';
        }


        return (
            <div className={lessonClassName}>
                {this.renderChangedIcon()}
                <h6 className="sub">{index + 1}</h6>
                <h5 className="name">{name}</h5>
                <div className="actions">
                    <div className="edit-icon" onClick={this.handleShowEdit}>
                        <FontAwesomeIcon icon={'cog'} />Editar
                    </div>
                    <div className="edit-icon" onClick={this.handleShowDelete}>
                        <FontAwesomeIcon icon={'trash'} />Deletar
                    </div>
                </div>

                <Modal
                    show={this.state.showEdit}
                    onHide={this.handleCloseEdit}
                    backdrop="static"
                    dialogClassName="modal-dialog-90w"
                    className="modal">
                    <Modal.Header closeButton>
                        <Modal.Title className="modal-title">Editar Aula1</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="modal-input-group modal-input-formation">
                            <label>título</label>
                            <TextareaAutosize 
                                value={this.state.inputName}
                                onChange={e => this.setState({ inputName: e.target.value }) }
                                placeholder="digite aqui o nome da aula..."
                                async />
                        </div>
                        <BlockUi tag='div' blocking={this.state.blocking} className="draft-editor-container">
                            <div className="controls">
                                <BlockStyleControls
                                    editorState={editorState}
                                    onToggle={this.toggleBlockType}
                                    />
                                <InlineStyleControls
                                    editorState={editorState}
                                    onToggle={this.toggleInlineStyle}
                                    />
                                <input
                                    type="file"
                                    name="insert-picture"
                                    id="insert-picture"
                                    accept='image/jpeg, image/png'
                                    value={this.state.inputImage}
                                    onChange={this.handleSelectedImage} />
                                <label className="style-button" htmlFor="insert-picture"><FontAwesomeIcon icon='image' /> Imagem (proporção 4:3, largura máxima 600px, tipo jpg ou png)</label>
                                <input
                                    type="file"
                                    name="insert-video"
                                    id="insert-video"
                                    accept='video/mp4'
                                    value={this.state.inputVideo}
                                    onChange={this.handleSelectedVideo} />
                                <label className="style-button" htmlFor="insert-video"><FontAwesomeIcon icon='video' /> Vídeo (tipo mp4)</label>
                                {/* <span className="style-button" onClick={this.onAddImage}>
                                    
                                </span> */}
                            </div>
                            
                            <div className={editorClassName} onClick={this.editorFocus}>
                                <Editor 
                                    blockStyleFn={getBlockStyle}
                                    blockRendererFn={mediaBlockRenderer}
                                    customStyleMap={styleMap}
                                    editorState={editorState}
                                    onChange={this.onChange}
                                    placeholder="Digite o conteúdo da aula aqui..."
                                    ref="editor"
                                    spellCheck={true}
                                />
                            </div>
                        </BlockUi>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={this.saveEditorChanges} type="orange" title="Salvar" />
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showDelete}
                    onHide={this.handleCloseDelete}
                    backdrop="static"
                    className="modal">
                    <Modal.Header closeButton>
                        <Modal.Title className="modal-title">Deletar Aula</Modal.Title>
                    </Modal.Header>

                    <Modal.Body style={{ minHeight: '100px', textAlign: 'center' }}>
                        <h3>A aula deletada não poderá ser recuperada. Você deseja continuar?</h3>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={this.handleDelete} type="orange" title="Sim" />
                        <Button onClick={this.handleCloseDelete} title="Não" />
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }



    // Handlers
    handleCloseEdit = () => {
        this.setState({ 
            showEdit: false
        });
    };

    handleShowEdit = () => {
        if (this.state.elements.length > 0) {
            let editorLoadState = this.loadElements(this.state.elements);

            this.setState({ inputName: this.state.name,
                            editorState: editorLoadState
                        }, () => this.setState({ showEdit: true }));
            ;
        } else {
            this.setState({ inputName: this.state.name,
                            editorState: EditorState.createEmpty(),
            }, () => this.setState({ showEdit: true }));
        }
        
    };

    handleCloseDelete = () => {
        this.setState({ 
            showDelete: false
        });
    };

    handleShowDelete = () => {
        this.setState({ showDelete: true });
    };

    handleDelete = () => {
        this.props.onLessonDelete(this.state.index);
        this.handleCloseDelete();
    }

    handleSelectedImage = (event) => {
        
        this.setState({ blocking: true });

        let file = event.target.files[0];
        let config = {
            bucketName: 'mri-ead',
            bucketRoot: 'https://s3-sa-east-1.amazonaws.com/mri-ead/',
            region: 'sa-east-1',
            accessKeyId: 'AKIAIBBX5O7CKQWW22HA',
            secretAccessKey: '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',
            dirName: 'images'
        };

        // TODO: LOADER SPINNER
        uploadFile(file, config, API_ROOT)
            .then(data => {
                this.setState({ 
                    blocking: false,
                    inputImage: '',
                });
                let editorState = this.onAddMedia(data.location, 'image', this.state.editorState);
                this.setState({ editorState: editorState });
            }, function() {
                this.setState({ 
                    blocking: false,
                    inputImage: '',
                });
                toast.error(`Erro subindo a imagem! Tente novamente!`, {
                    className: 'toast-error'
                });
            });
    
    };

    handleSelectedVideo = (event) => {

        this.setState({ blocking: true });

        let file = event.target.files[0];
        let config = {
            bucketName: 'mri-ead',
            bucketRoot: 'https://s3-sa-east-1.amazonaws.com/mri-ead/',
            region: 'sa-east-1',
            accessKeyId: 'AKIAIBBX5O7CKQWW22HA',
            secretAccessKey: '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',
            dirName: 'video'
        };

        // TODO: LOADER SPINNER
        uploadFile(file, config, API_ROOT)
            .then(data => {
                this.setState({ 
                    blocking: false,
                    inputVideo: ''
                });
                let editorState = this.onAddMedia(data.location, 'video', this.state.editorState);
                this.setState({ editorState: editorState });
            }, function() {
                this.setState({ 
                    blocking: false,
                    inputVideo: ''
                });
                toast.error(`Erro subindo o vídeo! Tente novamente!`, {
                    className: 'toast-error'
                });
            });

    }


    
    toggleBlockType = (blockType) => {
        this.onChange(
            RichUtils.toggleBlockType(
                this.state.editorState,
                blockType
            )
        );
    };

    toggleInlineStyle = (inlineStyle) => {
        this.onChange(
            RichUtils.toggleInlineStyle(
                this.state.editorState,
                inlineStyle
            )
        );
    };



    // Save and Load
    saveEditorChanges = async () => {
        let name = this.state.inputName;
        let elements = await this.saveEditorElements();
        
        this.setState({
            name: name,
            elements: elements,
        }, () => {
            this.props.onLessonEdit(this.state.index, name, elements);
            this.setState({ showEdit: false });
        })
    }

    saveEditorElements = () => {
        let elementsTemp = [];

        const contentState = this.state.editorState.getCurrentContent();
        let contentAsArray = convertToRaw(contentState);

        if (contentAsArray.blocks.length > 0) {
            contentAsArray.blocks.forEach(block => {
                let element = {
                    type: '',
                    text: ''
                }

                switch(block.type) {
                    case 'header-two' :
                        element.type = 13;
                        break;
                    case 'header-four' : 
                        element.type = 14;
                        break;
                    case 'blockquote' :
                        element.type = 12;
                        break;
                    case 'atomic-image' :
                        element.type = 51;
                        element.src = block.text;
                        break;
                    case 'atomic-video' :
                        element.type = 52;
                        element.src = block.text;
                        break;
                    default :
                        element.type = 11;
                        break;
                }

                element.text = block.text;

                elementsTemp.push(element);
            });
        }

        return elementsTemp;
    }



    loadElements = (elements) => {

        let editorState = EditorState.createEmpty();
        
        let contentType;
        elements.forEach(element => {
            switch(element.type) {
                case 13 :
                    contentType = 'header-two';
                    editorState = this.onAddBlock(element.text, contentType, editorState);
                    break;
                case 14 :
                    contentType = 'header-four';
                    editorState = this.onAddBlock(element.text, contentType, editorState);
                    break;
                case 12 :
                    contentType = 'blockquote';
                    editorState = this.onAddBlock(element.text, contentType, editorState);
                    break;
                case 51 :
                    contentType = 'image';
                    editorState = this.onAddMedia(element.src, contentType, editorState);
                    break;
                case 52 :
                    contentType = 'video';
                    editorState = this.onAddMedia(element.src, contentType, editorState);
                    break;
                default :
                    contentType = 'unstyled';
                    editorState = this.onAddBlock(element.text, contentType, editorState);
                    break;
            }

        });

        return editorState;
        // let contentBlocksArray = [];
        // contentBlocksArray = elements.map(element => {
        //     //console.log(element);
        //     let contentType;
        //     switch (element.type) {
        //         case 13 :
        //             contentType = 'header-two';
        //             break;
        //         case 14 : 
        //             contentType = 'header-four';
        //             break;
        //         case 12 :
        //             contentType = 'blockquote';
        //             break;
        //         case 51 :
        //             contentType = 'atomic';
        //             break;
        //         case 52 :
        //             contentType = 'atomic';
        //             break;
        //         default :
        //             contentType = 'unstyled';
        //             break;
        //     }

            // let contentBlock;
            // if (contentType === 'image' || contentType === 'video') {
            //     return new ContentBlock({
            //         key: genKey(),
            //         type: contentType,
            //         characterList: new List(Repeat(CharacterMetadata.create(), element.src.length)),
            //         text: element.src
            //     });
            // } else {
            //     return new ContentBlock({
            //         key: genKey(),
            //         type: contentType,
            //         characterList: new List(Repeat(CharacterMetadata.create(), element.text.length)),
            //         text: element.text
            //     });
            // }
            
        //     return new ContentBlock({
        //         key: genKey(),
        //         type: contentType,
        //         characterList: new List(Repeat(CharacterMetadata.create(), element.text.length)),
        //         text: element.text
        //     });
        // });

        // //console.log(contentBlocksArray);
        // return contentBlocksArray;
        //let test = convertFromRaw(JSON.parse(elements));
        //console.log(test);
    };

    onAddBlock = (text, type, editorState) => {
        // console.log(text);
        // console.log(type);
        // console.log(editorState);
        
        //const editorState = this.state.editorState;
        var contentState = editorState.getCurrentContent();
        var selectionState = editorState.getSelection();

        var afterRemoval = Modifier.removeRange(contentState, selectionState, 'backward');
        var targetSelection = afterRemoval.getSelectionAfter();
        var afterSplit = Modifier.splitBlock(afterRemoval, targetSelection);
        var insertionTarget = afterSplit.getSelectionAfter();

        var asBlock = Modifier.setBlockType(afterSplit, insertionTarget, type);

        var blockConfig = {
            key: genKey(),
            type: type,
            text: text,
            characterList: List(Repeat(CharacterMetadata.create(), text.length))
        }

        var fragmentArray = [new ContentBlock(blockConfig)];
        var fragment = BlockMapBuilder.createFromArray(fragmentArray);
        var withBlock = Modifier.replaceWithFragment(asBlock, insertionTarget, fragment);

        var newContent = withBlock.merge({
            selectionBefore: selectionState,
            selectionAfter: withBlock.getSelectionAfter().set('hasFocus', true)
        });

        return EditorState.push(editorState, newContent, 'insert-fragment');
    }

    onAddMedia = (urlValue, type, editorState) => {

        //const editorState = this.state.editorState;
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            type,
            'IMMUTABLE',
            { src: urlValue }
        );

        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity }, 'create-entity');

        return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, urlValue, type);

        // this.setState({
        //     editorState: AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, urlValue, type)
        // }, () => { 
        //     setTimeout(() => this.focus(), 0)
        // });
    };



    // _handleKeyCommand = (command, editorState) => {
    //     var newState = RichUtils.handleKeyCommand(editorState, command);
    //     if (newState) {
    //         this._onChange(newState);
    //         return true;
    //     }
    //     return false;
    // };

}


export default LessonItem;