import React from 'react';
import Api from '../../apis/api';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DropdownCustomMenu from './DropdownCustomMenu';
import Utils from '../Utils';



class DropdownSelection extends React.Component {

    constructor(props) {
        super(props);

        this.state ={
            query: '',
            showMenu: false,
            keyboardHoverItem: null,
            filteredItems: [],
        };

        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }



    render() {
        const { placeholder, type } = this.props;
        
        let inputSectionStyle = null;
        if (type === 1 || type === 2) {
            inputSectionStyle = {
                marginTop: 0
            }
        }


        return (
            <div className="input-group-section input-list-section">
                <label className="label-section">{this.props.label}</label>
                <input 
                    type="text"
                    value={this.state.query}
                    onChange={e => {
                        this.setState({ query: e.target.value},
                        () => this.filterItems())
                    }}
                    placeholder={placeholder} 
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    onKeyDown={this.handleKeyDown} />
                <DropdownCustomMenu 
                    show={this.state.showMenu}
                    type={type}
                    filteredItems={this.state.filteredItems}
                    keyboardHover={this.state.keyboardHoverItem} 
                    onAddItem={this.handleAddItem} />
                <ul className="input-items-section" style={inputSectionStyle}>
                    {this.renderSelectedItems()}
                </ul>
            </div>
            
        );
    }

    renderSelectedItems = () => {
        if (this.props.selectedItems.length < 1) { return; }

        let selectedItems = this.props.selectedItems.map((selectedItem, index) => {
            
            if (selectedItem === 0) { return null; }

            let itemData = this.props.items.find(item => {
                return item.id === selectedItem;
            });
            if (!itemData) { return; }

            let name = Utils.capitalizeFirstLetter(itemData.name);
            if (itemData.lastname) {
                name += ` ${Utils.capitalizeFirstLetter(itemData.lastname)}`;
            }


            // Text and image
            if (this.props.type === 1 || this.props.type === 2) {
                return (
                    <li className="list-round-item" key={index}>
                        <div
                            className="avatar-container"
                            style={{backgroundImage: `url(${itemData.image})`}}
                        />
                        <h3 className="name">{name}</h3>
                        {/* <ul className="list">{this.renderFormationItemsList()}</ul> */}
                        <div className="actions">
                            <div className="edit-icon" onClick={() => this.handleRemoveItem(index)}>
                                <FontAwesomeIcon icon={'times'} />
                            </div>
                        </div>
                    </li>
                );
            } 

            // Only text
            else if (this.props.type === 3) {
                return (
                    <li className="list-item" key={index}>{name}<FontAwesomeIcon onClick={() => this.handleRemoveItem(index)} icon={'times'} /></li>
                );
            }
            
            return null;
        });

        return selectedItems;
    }



    filterItems = async () => {
        let query = this.state.query;
        
        let filteredItems = this.props.items.filter(item => {

            if (this.props.selectedItems.includes(item.id)) { return null; }

            let itemValue = item.name.toLowerCase();
            // Search by teacher last name too
            if (this.props.type === 1) {
                itemValue += ` ${item.lastname.toLowerCase()}`;
            }


            let searchName, searchLastName;
            searchName = itemValue.toLowerCase().search(query);

            if (this.props.type === 1) {
                searchLastName = itemValue.toLowerCase().search(query);

                if (searchName === -1 && searchLastName === -1) { return null; }
            }

            if (searchName === -1) { return null; }


            return item;
        });

        await this.setState({ filteredItems: filteredItems });
    }



    handleFocus = () => {
        if (this.state.showMenu) { return; }

        this.filterItems();
        this.setState({ showMenu: true });
    }

    handleBlur = () => {
        var that = this;

        if (!that.state.showMenu) { return; }
        
        setTimeout(
            function() {
                that.setState({ 
                    showMenu: false,
                    keyboardHoverItem: null
                })
            }, 150);
    }

    handleKeyDown(ev) {

        let keyboardHover = this.state.keyboardHoverItem;

        // Enter
        if (ev.keyCode === 13) {
            if (keyboardHover === null) { 

                if (this.props.type === 3 && this.state.query !== '') {
                    this.createCategory(this.state.query);

                    return this.setState({ query: '' });
                }

                return; 
            }


            if (this.state.filteredItems.length < 1) { 
                return; 
            }


            let item = this.state.filteredItems[keyboardHover];
            this.handleAddItem(item);

            this.setState({ 
                showMenu: false, 
                keyboardHoverItem: null,
                query: ''
            }, () => {
                setTimeout(this.handleFocus, 250);
            });

        }


        // Empty list
        if (this.state.filteredItems.length < 1) { 
            return; 
        }


        // Arrows
        let itemsSize = this.state.filteredItems.length;

        if (ev.keyCode === 38) { // Up

            if (keyboardHover === null) {
                return this.setState({ keyboardHoverItem: itemsSize - 1 });
            } else {
                let newPosition = keyboardHover - 1;
                if (newPosition < 0) {
                    newPosition = itemsSize - 1;
                }

                return this.setState({keyboardHoverItem: newPosition });
            }

        } else if (ev.keyCode === 40) { // Down

            if (keyboardHover === null) { 
                return this.setState({ keyboardHoverItem: 0 });
            } else {
                let newPosition = keyboardHover + 1;

                if (newPosition >= itemsSize) {
                    newPosition = 0;
                }

                return this.setState({keyboardHoverItem: newPosition });
            }

        }

    }

    handleAddItem = (item) => {
        this.props.addSelectedItem(item);
    }

    handleRemoveItem = (index) => {
        this.props.removeSelectedItem(index);
    }



    async createCategory(text) {

        let category = await Api.categoriesPanelEditor(text);

        category = category.courses_categories;
        if (Utils.checkForErrors(this, category)) { return; }

        category = category.data.panel_editor;
        if (Utils.checkForErrors(this, category)) { return; }

        category = category.data;
        this.props.onCreateCategory(category);

    }

    // checkForErrors(element) {
    //     if (element.error_code !== 0) {
    //         this.setState({ error: element.error_code });

    //         toast.error(`Erro executando esta operação! Código de erro: ${element.error_code}`, {
    //             className: 'toast-error'
    //         });
            
    //         return true;
    //     }

    //     return false;
    // };
    
}


export default DropdownSelection;