import React from 'react';
import Api from '../apis/api';
import { uploadFile } from 'react-s3';
import { API_ROOT } from '../config';
import Utils from '../components/Utils';
import fs from 'fs'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import Button from '../components/common/Button';
import SponsorItem from '../components/js/SponsorItem';
import { toast } from 'react-toastify';
import { Row } from 'react-bootstrap';
import { number } from 'prop-types';


class ScreenSponsorsList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            showCreate: false,

            sponsors: [],
            page: 1,
            numberOfPages: undefined,
            buttonShowMore: true,

            inputImage: '',
            inputName: '',
            inputLink: '',

            error: 0
        };
    }
    
    componentDidMount() {
        this.fetchData(this.state.page, 20);
    };

    async fetchData(page, pageSize) {
        this.setState({ loading: true });

        let sponsors = await Api.sponsorsPanelList(page, pageSize);
        this.setState({numberOfPages: sponsors.sponsors.data.panel_list.data.pages});

        sponsors = sponsors.sponsors;
        if(Utils.checkForErrors(this, sponsors)) { return; };

        sponsors = sponsors.data.panel_list;
        if(Utils.checkForErrors(this, sponsors)) { return; };

        sponsors = sponsors.data.list;
        this.setState({ sponsors: [...this.state.sponsors, ...sponsors] }, () => this.setState({ loading: false }));

        this.setState({ page });        
        if(page === this.state.numberOfPages) {
            this.setState({ buttonShowMore: false });
            return
        }
    };

    // checkForErrors(element) {
    //     if (element.error_code !== 0) {
    //         this.setState({ error: element.error_code });

    //         toast.error(`Erro executando esta operação! Código de erro: ${element.error_code}`, {
    //             className: 'toast-error'
    //         });

    //         return true;
    //     }

    //     return false;
    // }



    renderList() {
        if (this.state.sponsors.length === 0) { 
            return (
                <p style={{ fontFamily: 'Averta', fontSize: '1.2rem' }}>Não há patrocinadores cadastrados!</p>
            ); 
        }

        const sponsorsList = this.state.sponsors.map((sponsor) => {
            return (
                <SponsorItem 
                    onSponsorDelete={this.handleSponsorDelete}
                    key={sponsor.id}
                    item={sponsor}
                />
            );
        });

        return sponsorsList;
    };

    render() {
        if (this.state.error !== 0) {
            return null;
        }


        return (
            <div className="main-content" style={{ textAlign: "center" }}>
                <div className="content-header fluid centered flex-between min-600">
                    <div></div>
                    <Button
                        onClick={this.handleShowCreate}
                        type="orange"
                        title="Adicionar novo patrocinador" />
                    
                    <Modal 
                        show={this.state.showCreate}
                        onHide={this.handleCloseCreate}
                        backdrop="static"
                        className="modal">
                        <Modal.Header closeButton>
                            <Modal.Title className="modal-title">Adicionar Patrocinador</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <div
                                className="modal-avatar-container"
                                style={{backgroundImage: `url(${this.state.inputImage})`}}>
                                <input
                                    type="file"
                                    name="edit-avatar"
                                    id="edit-avatar"
                                    accept="image/png, image/jpeg"
                                    onChange={this.handleSelectedFile} />
                                <label htmlFor="edit-avatar">BUSCAR</label>
                            </div>
                            <div className="modal-card-data">
                                <div className="modal-input-group modal-input-name">
                                    <label>nome (*)</label>
                                    <input 
                                        type="text"
                                        value={this.state.inputName}
                                        onChange={e => this.setState({ inputName: e.target.value}) }
                                        placeholder="digite aqui o nome..." />
                                </div>
                                <div className="modal-input-group modal-input-name">
                                    <label>link</label>
                                    <input 
                                        type="text"
                                        value={this.state.inputLink}
                                        onChange={e => this.setState({ inputLink: e.target.value}) }
                                        placeholder="digite aqui o link..." />
                                </div>
                            </div>
                        </Modal.Body>

                        <div style={{textAlign:'center'}}>
                            <label className="style-button" htmlFor="insert-picture"><FontAwesomeIcon icon='image' /> obs.: imagem (proporção 1:1, largura máxima 600px, tipo jpg ou png)</label>
                        </div>

                        <Modal.Footer>
                            <Button onClick={this.saveChanges} type="orange" title="Salvar" />
                        </Modal.Footer>
                    </Modal>
                </div>
                <div id="sponsors-list">
                    {this.renderList()}
                    {this.state.buttonShowMore && <Row style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center'
                    }}>
                        <Button
                            onClick={() => this.fetchData(this.state.page + 1, 20)}
                            // style={{ width: '140px' }}
                            // icon={this.state.published ? 'arrow-down' : 'arrow-up'}
                            type="orange"
                            title={'Ver mais'} />
                    </Row>}
                </div>
            </div>
        );
    }



    handleCloseCreate = () => {
        this.setState({ showCreate: false });
    };

    handleShowCreate = () => {
        this.setState({ showCreate: true });
    };

    handleSelectedFile = (event) => {
        let file = event.target.files[0];

        file = new File([file], 'foto' , { type: file.type });
        console.log(file)
        let config = {
            bucketName: 'mri-ead',
            bucketRoot: 'https://s3-sa-east-1.amazonaws.com/mri-ead/',
            region: 'sa-east-1',
            accessKeyId: 'AKIAIBBX5O7CKQWW22HA',
            secretAccessKey: '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',
            dirName: 'images'
        };

        // TODO: LOADER SPINNER
        uploadFile(file, config, API_ROOT)
            .then(data => {
                this.setState({ inputImage: data.location });
                toast.success('Imagem enviada com sucesso!', {
                    className: 'toast-success'
                });
            }, function() {
                toast.error(`Erro subindo a imagem! Tente novamente!`, {
                    className: 'toast-error'
                });
            });
    
    };

    saveChanges = async () => {
        let name = this.state.inputName;
        let link = this.state.inputLink;
        let image = this.state.inputImage;

        // Validações dos campos obrigatórios para salvar
        if (!this.validateRequiredFields(name, link)) {
            return;
        }

        let upload = await Api.sponsorPanelEditor('', name, link, image);
        console.log(upload)

        upload = upload.sponsors;
        if (Utils.checkForErrors(this, upload)) { return; }

        upload = upload.data.panel_editor;
        if (Utils.checkForErrors(this, upload)) { return; }

        upload = upload.data;
        if (Utils.checkForErrors(this, upload)) { return; }


        toast.success('Patrocinador criado com sucesso!', {
            className: 'toast-success'
        });


        this.setState({
            inputImage: '',
            inputName: ''
        }, () => {
            this.setState({ showCreate: false });
            this.setState({ sponsors: [{
                id: upload.id,
                link: upload.link,
                name: upload.name,
                image: upload.image,
            }, ...this.state.sponsors]})
        });
    }

    validateRequiredFields(name, link) {

        let requiredFieldsOK = true;
        if ((name == '') || ((link != '') && (!link.includes('https')))) {
            
            toast.info('Verifique os campos obrigatórios com (*) e se o link possue https, para poder salvar a edição do patrocinador.', {
                className: 'toast-info'
            });

            requiredFieldsOK = false;
        }

        return requiredFieldsOK;
    }

    handleSponsorDelete = async (id) => {
        let del = await Api.sponsorPanelDelete(id);

        del = del.sponsors;
        if (Utils.checkForErrors(this, del)) { return; };

        del = del.data.panel_rem;
        if (Utils.checkForErrors(this, del)) { return; };

        if (del.data) {
            toast.success('Patrocinador deletado com sucesso!', {
                className: 'toast-success'
            });
            const removeSponsor = this.state.sponsors.filter(item => item.id !== id)
            this.setState({sponsors: removeSponsor})
        }
    }
    
};


export default ScreenSponsorsList;