import React from 'react';
import Api from '../apis/api';
import Utils from '../components/Utils';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row } from 'react-bootstrap';
import Button from '../components/common/Button';
import CoursePanelListItem from '../components/js/CoursePanelListItem';


class ScreenCoursesList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            courses: [],

            published: '',
            category: '',
            query: '',
            page: 1,
            order: '',

            error: 0
        };

    }


    componentDidMount() {
        this.fetchData('', '', '', this.state.page, 15, '');
    };

    handleClickNextPage = () => {
        this.setState({ page: this.state.page + 1 }, () => this.fetchData('', '', '', this.state.page, 15, ''));
    }
    render() {
        if (this.state.error !== 0) {
            return null;
        }

        return (
            <div className="main-content min-600 centered" style={{ textAlign: "center" }}>
                <div className="content-header centered flex-between min-600">
                    <div></div>
                    <Link
                        style={{ width: '240px' }}
                        className="button orange-bg"
                        to={'/cursos/editor'}
                        title="Adicionar novo curso" >
                        <FontAwesomeIcon icon='plus' />Adicionar novo curso
                    </Link>
                </div>
                <div id="courses-list" style={{ minHeight: '100px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    {this.renderList()}
                </div>
                <Row style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center'
                }}>
                    <Button
                        onClick={this.handleClickNextPage}
                        // style={{ width: '140px' }}
                        // icon={this.state.published ? 'arrow-down' : 'arrow-up'}
                        type="orange"
                        title={'Ver mais'} />
                </Row>
            </div>
        );
    };

    async fetchData(published, category, query, page, pageSize, order) {
        this.setState({ loading: true });

        let courses = await Api.coursesPanelList(published, category, query, page, pageSize, order);

        courses = courses.courses;
        if (Utils.checkForErrors(this, courses)) { return; };

        courses = courses.data.panel_list;
        if (Utils.checkForErrors(this, courses)) { return; };

        courses = courses.data.list;
        //console.log('courses', courses);
        this.setState({ courses: [...this.state.courses, ...courses] }, () => this.setState({ loading: false }));
    };

    renderList() {
        if (this.state.courses.length === 0) {
            return (
                <p style={{ fontFamily: 'Averta', fontSize: '1.2rem' }}>Não há cursos cadastrados!</p>
            );
        }

        const coursesList = this.state.courses.map((course) => {
            //console.log(course);
            return (
                <CoursePanelListItem
                    onCourseDelete={this.handleCourseDelete}
                    onCoursePublish={this.handleCoursePublish}
                    key={course.id}
                    item={course}
                />
            );
        });
        return coursesList;
    };

    handleCourseDelete = async (id) => {
        let del = await Api.coursesPanelDelete(id);

        del = del.courses;
        if (Utils.checkForErrors(this, del)) { return; };

        del = del.data.panel_rem;
        if (Utils.checkForErrors(this, del)) { return; };

        if (del.data) {
            this.fetchData('', '', '', 1, 20, '');
        }
    }

    handleCoursePublish = async (id, published) => {
        let pub = await Api.coursesPanelPublish(id, !published);

        pub = pub.courses;
        if (Utils.checkForErrors(this, pub)) { return; };

        pub = pub.data.panel_publish;
        if (Utils.checkForErrors(this, pub)) { return; };

        if (pub.data) {
            this.fetchData('', '', '', 1, 20, '');
        }
    }

};


export default ScreenCoursesList;