import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../../assets/images/icon_mri.png';



const HeaderItem = (props) => {

    if (props.title) {
        return (
            <Link className="header-item" to={props.path} title={props.title}>
                {props.title}
            </Link>
        );
    }


    return (
        <Link className="header-item" to={props.path}>
            <img src={logo} alt={props.alt} />
        </Link>
    );
    
};



export default HeaderItem;