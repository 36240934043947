import React from 'react';



const Image = props => {
    if (!!props.src) {
        return <img src={props.src} alt='' />;
    }

    return null;
};

const Video = props => {
    if (!!props.src) {
        return (
            <video controls src={props.src}>
                {/* <source src={} type='video/mp4'></source>
                Seu browser não suporta o player de vídeo. */}
            </video>
        );
    }

    return null;
};


export const Media = props => {
    
    const { block, contentState } = props;
    const entity = contentState.getEntity(block.getEntityAt(0));

    if (entity) {
        const { src } = entity.getData();
        const type = entity.getType();
    
        let media;
        if (type === 'image') {
            media = <Image src={src} />;
        } else if (type === 'video') {
            media = <Video src={src} />;
        }
    
        return media;
    }
    
};


export const mediaBlockRenderer = (block) => {
    if (block.getType() === 'atomic-image' || block.getType() === 'atomic-video') {
        return {
            component: Media,
            editable: false
        };
    }
};