import '../css/app.css';
import React from 'react';
import Api from '../../apis/api';
import Utils from '../Utils';
import { uploadFile } from 'react-s3';
import { API_ROOT } from '../../config';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import Button from '../common/Button';
import TextareaAutosize from 'react-autosize-textarea';
import iconeSpin from '../../assets/images/icone_spin.png';

import { toast } from 'react-toastify';


class UserItem extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showAddCredit: false,

            id: '',
            name: '',
            lastname: '',
            phoneNumber: '',
            credits: '',

            inputCredits: '',
        };
    };

    componentDidMount = (props) => {
        const { item } = this.props;
        
        this.setState({
            id: item.id,

            name: item.name,
            lastname: item.lastname,
            email: item.email,
            phoneNumber: item.phone_number,
            credits: item.credits,
        });
    };

    render() {
        const { name, lastname, email, phoneNumber, credits } = this.state;

        //<FontAwesomeIcon icon={'credit-card'} />
        return (
            <div>
                <table style={{width: '100%'}}>
                    <tbody>
                        <tr>
                            <td style={{width: '30%'}}>
                                <p className="name">{name + ' ' + lastname}</p>
                            </td>
                            <td style={{width: '30%'}}>
                                <p className="name">{email}</p>
                            </td>
                            <td style={{width: '20%'}}>
                                <p className="name">{phoneNumber}</p>
                            </td>
                            <td style={{width: '10%'}}>
                                <p className="name">{credits}</p>
                            </td>
                            <td style={{width: '10%'}}>
                                <div className="actions">
                                    <div className="edit-icon" onClick={this.handleShowAdd} style={{cursor:'pointer'}}>
                                        <img src={iconeSpin} alt="Spin" width={40} />
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <Modal 
                    show={this.state.showAddCredit}
                    onHide={this.handleCloseAdd}
                    backdrop="static"
                    className="modal">
                    <Modal.Header closeButton>
                        <Modal.Title className="modal-title">Adicionar Crédito</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="modal-card-data">
                            <div className="modal-input-group modal-input-name">
                                <label>nome: {this.state.name + ' ' + this.state.lastname}</label>
                            </div>
                            <div className="modal-input-group modal-input-name" style={{paddingTop: 40}}>
                                <label>crédito atual (spins): {this.state.credits}</label>
                            </div>
                            <div className="modal-input-group modal-input-name" style={{paddingTop: 25}}>
                                <label>spins(*)</label>
                                <input 
                                    type="text"
                                    maxLength={5}
                                    value={this.state.inputCredits}
                                    onChange={e => this.setState({ inputCredits: e.target.value}) }
                                    placeholder="digite aqui a quantidade de spins..." />
                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={this.handleAddCredit} type="orange" title="Salvar" />
                    </Modal.Footer>
                </Modal>
                
            </div>
        );
    };

    // Handlers
    handleShowAdd = () => {
        this.setState({ showAddCredit: true });
    };
    
    handleCloseAdd = () => {
        this.setState({ 
            showAddCredit: false
        });
    };
    
    handleAddCredit = () => {
        this.setState({ showAddCredit: true });

        let credits = this.state.inputCredits;

        // Validações dos campos obrigatórios para salvar
        if (!this.validateRequiredFields(credits)) {
            return;
        }

        this.setState({ showAddCredit: false });

        this.props.onSaveChanges(credits, this.state.id);
        this.handleCloseAdd();

    };

    validateRequiredFields(value) {

        let requiredFieldsOK = true;
        if ((value == '') || (value == 0) || isNaN(value) || (Number(value) > 99999)) {
            
            toast.info('Verifique o campo obrigatório com (*) para poder salvar a adição dos créditos.', {
                className: 'toast-info'
            });

            requiredFieldsOK = false;
        }

        return requiredFieldsOK;
    }
}


export default UserItem;