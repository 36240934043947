let environment, bucketURL;
//const hostname = window && window.location && window.location.hostname;
const hostname = "https://api-staging.mrionline.com.br/";

////console.log(hostname);
//if (hostname === 'localhost' || hostname === 'mri-ead-panel-dev.herokuapp.com') {
//    environment = 'dev';
//} else if (hostname === 'mri-ead-panel-staging.herokuapp.com') {
//    environment = 'stag';
//} else {
//    environment = process.env.NODE_ENV;
//}
environment = 'stag';
bucketURL = '';

export const API_ROOT = `${environment}`;
export const BUCKET_ROOT = `${bucketURL}`;