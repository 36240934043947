import React from 'react';
import { BrowserRouter, Redirect, Route } from 'react-router-dom';
import Api from '../apis/api';

// Toast
import { ToastContainer } from 'react-toastify';

// Components and screens
import ScreenAuth from '../screens/ScreenAuth';
import ScreenCourseEdit from '../screens/ScreenCourseEdit';
import ScreenCoursesList from '../screens/ScreenCoursesList';
import ScreenEventEdit from '../screens/ScreenEventEdit';
import ScreenEventsAttendances from '../screens/ScreenEventsAttendances';
import ScreenEventsList from '../screens/ScreenEventsList';
import ScreenFinances from '../screens/ScreenFinances';
import ScreenPlansList from '../screens/ScreenPlansList';
import ScreenSponsorsList from '../screens/ScreenSponsorsList';
import ScreenStampsList from '../screens/ScreenStampsList';
import ScreenStats from '../screens/ScreenStats';
import ScreenTeachersList from '../screens/ScreenTeachersList';
import ScreenUsersList from '../screens/ScreenUsersList';
import Header from './js/Header';

// CSS
import 'react-block-ui/style.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-table/react-table.css';
import 'react-toastify/dist/ReactToastify.css';
import './css/app.css';
import ScreenDeleteAccount from '../screens/ScreenDeleteAccount';




// import Auth from './Auth';
// import SearchBar from './SearchBar';
// import CoursesPanelList from './CoursesPanelList';
// import TeachersList from './TeachersList';


// const checkAuth = () => {
//     const token = localStorage.getItem('userToken');
//     //const refreshToken = localStorage.getItem('refreshToken');

//     if (!token) { return false; }

//     try {
//         let user = Api.refresh(token);

//         user.then(function(result) {
//             console.log(result);
//             return true;
//         });
//         //console.log(user);
//     } catch(err) {
//         console.log(err);
//         return false;
//     }
// };

const renderMergedProps = (component, ...rest) => {
    const finalProps = Object.assign({}, ...rest);
    return (
        React.createElement(component, finalProps)
    );
};

const PropsRoute = ({ component, ...rest }) => {
    return (
        <Route 
            {...rest}
            render={routeProps => {
                return renderMergedProps(component, routeProps, rest);
            }} />
    );
};

const PrivateRoute = ({ component, ...rest}) => {
    return (
        <Route
            {...rest}
            render={props => {
                return (
                    rest.logged ? ( 
                        renderMergedProps(component, props, rest)
                    ) : ( 
                        <Redirect to={{ pathname: "/login", state: { from: props.location } }} /> 
                    )
                );
            }}
            />
    );
}


class App extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,

            userId: '',
            userEmail: '',
            userName: '',
            userLastName: '',
            userAvatar: '',
            userToken: '',

            logged: false
        };
    }


    componentDidMount() {
        const token = localStorage.getItem('userToken');

        var that = this;
        if (!token) { return; }

        this.setState({ loading: true });
        let user = Api.refresh(token);
        user.then(function(result) {
            that.onUserLogin(result.user);
        }, function(err) {
            console.log(err);
            that.onUserLogout();
        });
    }

    onUserLogin = async (user) => {
        await Api.setTokenInHeader(user.token);
        localStorage.setItem('userId', user.id);
        localStorage.setItem('userEmail', user.email);
        localStorage.setItem('userName', user.name);
        localStorage.setItem('userLastName', user.lastname);
        localStorage.setItem('userAvatar', user.avatar);

        this.setState({ 
            userId : user.id,
            userEmail: user.email,
            userName: user.name,
            userLastName: user.lastname,
            userAvatar: user.avatar,
            userToken: user.token,
            logged: true,
        }, () => {
            this.setState({
                loading: false
            })
        });
        //this.setState({ loading: false });
    }

    onUserLogout = async () => {
        await Api.setTokenInHeader('');
        localStorage.removeItem('userId');
        localStorage.removeItem('userEmail');
        localStorage.removeItem('userName');
        localStorage.removeItem('userLastName');
        localStorage.removeItem('userAvatar');

        this.setState({ 
            loading: true 
        }, () => {
            this.setState({
                userId : '',
                userEmail: '',
                userName: '',
                userLastName: '',
                userAvatar: '',
                userToken: '',
                logged: false
            })
        });
        this.setState({ loading: false });
    }


    
    render() {
        return (
            <div style={{ height: '100%'}}>
                <BrowserRouter>
                    <div className="main">
                        <PropsRoute 
                            path="/login"
                            component={ScreenAuth}
                            onFormSubmit={this.onUserLogin} 
                            logged={this.state.logged}
                            loading={this.state.loading}
                        />

                        <Route
                            path="/excluir-conta"
                            render={() => (<ScreenDeleteAccount />)}
                        />
            
                        <Header
                            onUserLogout={this.onUserLogout}
                            logged={this.state.logged} />
                        
                        <PrivateRoute 
                            exact path="/"
                            component={ScreenCoursesList} 
                            logged={this.state.logged}
                            />
                        <PrivateRoute
                            exact path="/cursos"
                            component={ScreenCoursesList} 
                            logged={this.state.logged}
                            />
                        <PrivateRoute 
                            path="/cursos/editor"
                            component={ScreenCourseEdit}
                            logged={this.state.logged}
                            etc={this.props.match}
                            />
                        <PrivateRoute
                            exact path="/eventos"
                            component={ScreenEventsList} 
                            logged={this.state.logged}
                            />
                        <PrivateRoute 
                            path="/eventos/editor"
                            component={ScreenEventEdit}
                            logged={this.state.logged}
                            etc={this.props.match}
                            />
                        <PrivateRoute
                            exact path="/eventos/presencas"
                            component={ScreenEventsAttendances} 
                            logged={this.state.logged}
                            />
                        <PrivateRoute 
                            path="/professores"
                            component={ScreenTeachersList} 
                            logged={this.state.logged}
                            />
                        <PrivateRoute 
                            path="/selos"
                            component={ScreenStampsList} 
                            logged={this.state.logged}
                            />
                        <PrivateRoute 
                            path="/patrocinadores"
                            component={ScreenSponsorsList} 
                            logged={this.state.logged}
                            />
                        <PrivateRoute 
                            path="/usuários"
                            component={ScreenUsersList} 
                            logged={this.state.logged}
                            />
                        <PrivateRoute 
                            path="/planos"
                            component={ScreenPlansList} 
                            logged={this.state.logged}
                            />
                        <PrivateRoute 
                            path="/estatisticas"
                            component={ScreenStats} 
                            logged={this.state.logged}
                            />
                        <PrivateRoute 
                            path="/financeiro"
                            component={ScreenFinances} 
                            logged={this.state.logged}
                        />
                    </div>
                </BrowserRouter>
                <ToastContainer
                    position='bottom-right'
                    autoClose={6000}
                    hideProgressBar={true}
                    closeOnClick
                    pauseOnHover
                    pauseOnVisibilityChange
                    draggable />
            </div>
        );
    };

}


export default App;


{/* <Route path="/login" exact component={ScreenAuth} />
<Header logged={this.state.logged} />
<Route path="/" exact component={ScreenCoursesList} />
<Route path="/cursos" exact component={ScreenCoursesList} />
<Route path="/cursos/editor" component={ScreenCourseEdit} />
<Route path="/professores" exact component={ScreenTeachersList} />
<Route path="/selos" exact component={ScreenStampsList} />
<Route path="/patrocinadores" exact component={ScreenSponsorsList} />
<Route path="/estatisticas" exact component={ScreenStats} /> */}