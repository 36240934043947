import React from 'react';
import Api from '../apis/api';

import { Redirect } from 'react-router-dom';

import mainLogo from '../assets/images/logo_mri.png';
import Button from '../components/common/Button';
import { BounceLoader } from 'react-spinners';
import { toast } from 'react-toastify';


class ScreenAuth extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            loading: false,

            email: '',
            password: '',

            error: 0
        };
    }


    onFormSubmit = async (event) => {
        event.preventDefault();

        let user = null;
        if (!this.state.email || !this.state.password) { return; }

        this.setState({ loading: true });

        try {
            user = await Api.login(this.state.email, this.state.password);

            user = user.user;
            this.props.onFormSubmit(user);
            
            return this.setState({ loading: false });
        } catch(err) {
            console.log(err);
            
            toast.error(`Verifique o email e senha informados.`, {
                className: 'toast-error'
            });

            return this.setState({ loading: false });
        }

    }


    render() {
        let { from } = this.props.location.state || { from: { pathname: '/' }};

        if (this.props.logged) {
            return <Redirect to={ from } />
        }

        if (this.state.error !== 0) {
            return null;
        }

        return (
            <div id="auth-component" className="min-600 centered orange-gradient">
                {/* Logo */}
                <div className="logo-container">
                    <img src={mainLogo} alt="MRI Online Dashboard" />
                </div>

                { this.props.loading || this.state.loading ? (
                    <div className="loading" style={{ height: '176px' }}>
                        <BounceLoader 
                            sizeUnit={"px"}
                            size={60}
                            color={'#fff'}
                            loading={this.props.loading}
                        />
                    </div>
                ) : (
                    <form onSubmit={this.onFormSubmit}>

                        {/* Email */}
                        <div className="input-section">
                            <label className='input-label'>Email</label>
                            <input 
                                type='text'
                                className='input-field'
                                value={this.state.email}
                                onChange={(event) => this.setState({ email: event.target.value })}
                            />
                        </div>

                        {/* Password */}
                        <div className="input-section">
                            <label className='input-label'>Senha</label>
                            <input 
                                type='password'
                                className='input-field'
                                value={this.state.password}
                                onChange={(event) => this.setState({ password: event.target.value })}
                            />
                        </div>
                        
                        {/* Submit */}
                        <Button
                            style={{ width: '200px', marginLeft: 'auto', marginRight: 'auto' }}
                            title="Entrar"
                            onClick={this.onFormSubmit} />

                    </form>
                )}
                
            </div>
        );
    }
}


export default ScreenAuth;