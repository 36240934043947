import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



const Button = (props) => {
    if (props.type === "orange") {

        return (
            <button
                onClick={props.onClick}
                className={`button orange-bg ${props.class}`}
                title={props.title}
                style={props.style}>
                { props.icon ? <FontAwesomeIcon icon={props.icon} /> : null }{props.title}
            </button>
        );
    }

    return (
        <button
            onClick={props.onClick}
            className={`button white-bg ${props.class}`}
            title={props.title}
            style={props.style}>
            {props.title}
        </button>
    );
}



export default Button;