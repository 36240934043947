import React from 'react';
import Api from '../../apis/api';

class EventLine extends React.Component {

    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);

        this.state = {
            hasAttendance: false,
            id: 0
        };
    }

    componentDidMount() {
        
        if (this.props.item) {
          
            this.setState({
                hasAttendance: this.props.item.has_attendance,
                id: this.props.item.id
            });
        }
    };

    async handleInputChange() {

        if (this.state.hasAttendance) {
            await Api.eventAttendancesPanelDelete(this.state.id);

            this.setState({
                hasAttendance: false,
                id: 0
            });
        }
        else {

            let ret = await Api.eventAttendancesPanelEditor(this.props.eventId, this.props.item.id_user, (this.props.date.getTime() / 1000));

            this.setState({
                hasAttendance: true,
                id: ret.events_attendances.data.panel_editor.data.id
            });
        }        
    }

    render() {

        //console.log(props);
        let classStyle = 'table-line';
        if (this.props.classStyle) {
            classStyle += ` ${this.props.classStyle}`;
        }

        let fontstyle = 'normal';
        if (this.props.font && this.props.font === 'bold') {
            fontstyle = 'bold';
        }

        return (
            <div className={classStyle}>
                {this.props.data.map((cell, i) => {
                    //cell.content = cell.content.replace();

                    if (cell.size !== 0) {
                        return (
                            <div key={i} className='table-cell' style={{ width: `${cell.size}%` }}>
                                <span style={{ fontWeight: `${fontstyle}`, width: '100%', textAlign: `${cell.center ? 'center' : 'auto'}` }}>
                                    {cell.hasCheck
                                    ? 
                                        <input
                                            name="isLink"
                                            type="checkbox"
                                            style={{width: 25, height: 25}}
                                            checked={this.state.hasAttendance}
                                            onChange={this.handleInputChange}
                                        />
                                    : cell.content}
                                </span>
                                {i < this.props.data.length - 1 ? <div className="divider" /> : ''}
                            </div>
                        )
                    } else {
                        return (
                            <div key={i} className='table-cell' style={{ flex: 1 }}>
                                <span style={{ fontWeight: `${fontstyle}` }}>
                                    {cell.hasCheck
                                    ? <input
                                        name="isLink"
                                        type="checkbox"
                                        checked={this.state.hasAttendance}
                                        onChange={this.handleInputChange}
                                        />
                                    : cell.content}
                                </span>
                                {i !== this.props.data.length ? <div className="divider" /> : ''}
                            </div>
                        )
                    }
                })}
            </div>
        );
    }
// style={{ width: cell.size !== 0 ? `${cell.size}%` : 'auto',
//                                 flex: cell.size === 0 ? '1' : '0' }}>

}

export default EventLine;