import React from 'react';
import Api from '../apis/api';
import mainLogo from '../assets/images/logo_mri.png';
import Button from '../components/common/Button';
import { ToastContainer, toast } from 'react-toastify';


class ScreenDeleteAccount extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      email: '',

      error: 0
    };
  }


  onFormSubmit = async (event) => {
    event.preventDefault();

    try {
      await Api.destroyAccount(this.state.email)
      toast.success('Conta excluída com sucesso!');
    } catch (error) {
      toast.error('Usuário não encontrado!');
    }
  }


  render() {
    return (
      <div id="auth-component" className="min-600 centered orange-gradient">
        {/* Logo */}
        <div className="logo-container">
          <img src={mainLogo} alt="MRI Online Dashboard" />
          <p style={{ marginTop: 30 }}>Para excluir sua conta, digite seu email e clique en "Ecluir conta" para confirmar.</p>
        </div>


        <form onSubmit={this.onFormSubmit}>

          {/* Email */}
          <div className="input-section">
            <label className='input-label'>Email</label>
            <input
              type='text'
              className='input-field'
              value={this.state.email}
              onChange={(event) => this.setState({ email: event.target.value })}
            />
          </div>

          {/* Submit */}
          <Button
            style={{ width: '200px', marginLeft: 'auto', marginRight: 'auto' }}
            title="Ecluir conta"
            onClick={this.onFormSubmit} />

        </form>
      </div>
    );
  }
}


export default ScreenDeleteAccount;