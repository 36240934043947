import React from 'react';
import Api from '../apis/api';
import Utils from '../components/Utils';
import { uploadFile } from 'react-s3';
import { API_ROOT } from '../config';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import Button from '../components/common/Button';
import TeacherItem from '../components/js/TeacherItem';
import TextareaAutosize from 'react-autosize-textarea';

import { toast } from 'react-toastify';


class ScreenTeachersList extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            showCreate: false,

            teachers: [],
            page: 1,

            inputImage: '',
            inputName: '',
            inputLastName: '',
            inputFormation: '',
            inputFormationList: [],

            error: 0
        };
    }

    componentDidMount() {
        this.fetchData(1, 20);
    };
    
    async fetchData(page, pageSize) {
        this.setState({ loading: true });

        let teachers = await Api.teachersPanelList(page, pageSize);
        
        teachers = teachers.teachers;
        if (Utils.checkForErrors(this, teachers)) { return; };
        
        teachers = teachers.data.panel_list;
        if (Utils.checkForErrors(this, teachers)) { return; };

        teachers = teachers.data.list;
        this.setState({ teachers: teachers }, () => this.setState({ loading: false }));
    }

    // checkForErrors(element) {
    //     if (element.error_code !== 0) {
    //         this.setState({ error: element.error_code });

    //         toast.error(`Erro executando esta operação! Código de erro: ${element.error_code}`, {
    //             className: 'toast-error'
    //         });

    //         return true;
    //     }

    //     return false;
    // }



    renderFormationItemsView = () => {
        const list = this.state.inputFormationList.map((formation, index) => {
            let text = Utils.capitalizeFirstLetter(formation);
            return (
                <li key={index} className="modal-input-item">{ text }<FontAwesomeIcon onClick={() => this.handleArrayRemove(index)} icon={'times'} /></li>
            );
        });

        return list;
    }

    renderList() {
        if (this.state.teachers.length === 0) { 
            return (
                <p style={{ fontFamily: 'Averta', fontSize: '1.2rem' }}>Não há professores cadastrados!</p>
            ); 
        }

        const teachersList = this.state.teachers.map((teacher) => {
            return (
                <TeacherItem
                    onTeacherDelete={this.handleTeacherDelete}
                    key={teacher.id}
                    item={teacher}
                />
            ); 
        });

        return teachersList;
    };

    render() {
        if (this.state.error !== 0) {
            return null;
        }

        return (
            <div className="main-content" style={{ textAlign: "center" }}>
                <div className="content-header fluid centered flex-between min-600">
                    <div></div>
                    <Button
                        onClick={this.handleShowCreate}
                        type="orange"
                        title="Adicionar novo professor" />

                    <Modal 
                        show={this.state.showCreate}
                        onHide={this.handleCloseCreate}
                        backdrop="static"
                        className="modal">
                        <Modal.Header closeButton>
                            <Modal.Title className="modal-title">Adicionar Professor</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <div
                                className="modal-avatar-container"
                                style={{backgroundImage: `url(${this.state.inputImage})`}}>
                                <input
                                    type="file"
                                    name="edit-avatar"
                                    id="edit-avatar"
                                    onChange={this.handleSelectedFile} />
                                <label htmlFor="edit-avatar">buscar</label>
                            </div>
                            <div className="modal-card-data">
                                <div className="modal-input-group modal-input-name">
                                    <label>nome (*)</label>
                                    <input 
                                        type="text"
                                        value={this.state.inputName}
                                        onChange={e => this.setState({ inputName: e.target.value}) }
                                        placeholder="digite aqui o nome..." />
                                </div>
                                <div className="modal-input-group modal-input-name">
                                    <label>sobrenome (*)</label>
                                    <input
                                        type="text"
                                        value={this.state.inputLastName}
                                        onChange={e => this.setState({ inputLastName: e.target.value}) }
                                        placeholder="digite aqui o sobrenome..." />
                                </div>
                                <div className="modal-input-group modal-input-formation">
                                    <label>formação</label>
                                    <TextareaAutosize 
                                        value={this.state.inputFormation}
                                        onChange={e => this.setState({ inputFormation: e.target.value }) }
                                        onKeyPress={this.handleArrayAdd}
                                        placeholder="digite aqui uma formação e pressione enter..."
                                        async />
                                    <ul className="modal-input-items">
                                        {this.renderFormationItemsView()}
                                    </ul>
                                </div>
                            </div>
                        </Modal.Body>

                        <div style={{textAlign:'center'}}>
                            <label className="style-button" htmlFor="insert-picture"><FontAwesomeIcon icon='image' /> obs.: imagem (proporção 1:1, largura máxima 600px, tipo jpg ou png)</label>
                        </div>

                        <Modal.Footer>
                            <Button onClick={this.saveChanges} type="orange" title="Salvar" />
                        </Modal.Footer>
                    </Modal>
                </div>
                <div id="teachers-list">
                    {this.renderList()}
                </div>
            </div>
        );
    };



    handleCloseCreate = () => {
        this.setState({ showCreate: false });
    };

    handleShowCreate = () => {
        this.setState({ showCreate: true });
    };



    handleArrayAdd = (event) => {
        if (event.key !== 'Enter') { return; }
        event.preventDefault();

        if (this.state.inputFormation !== '') {
            this.setState(
                { inputFormationList: [...this.state.inputFormationList, Utils.capitalizeFirstLetter(this.state.inputFormation)]},
                () => this.setState({ inputFormation: '' }));
        }
    };

    handleArrayRemove(index) {
        this.setState(
            { inputFormationList: [...this.state.inputFormationList.slice(0, index), ...this.state.inputFormationList.slice(index + 1)] }
        );
    }

    handleSelectedFile = (event) => {
        
        let file = event.target.files[0];
        let config = {
            bucketName: 'mri-ead',
            bucketRoot: 'https://s3-sa-east-1.amazonaws.com/mri-ead/',
            region: 'sa-east-1',
            accessKeyId: 'AKIAIBBX5O7CKQWW22HA',
            secretAccessKey: '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',
            dirName: 'images'
        };

        // TODO: LOADER SPINNER
        uploadFile(file, config, API_ROOT)
            .then(data => {
                this.setState({ inputImage: data.location });
                toast.success('Imagem enviada com sucesso!', {
                    className: 'toast-success'
                });
            }, function() {
                toast.error(`Erro subindo a imagem! Tente novamente!`, {
                    className: 'toast-error'
                });
            });
    
    };

    handleTeacherDelete = async (id) => {
        let del = await Api.teacherPanelDelete(id);

        del = del.teachers;
        if (Utils.checkForErrors(this, del)) { return; };

        del = del.data.panel_rem;
        if (Utils.checkForErrors(this, del)) { return; };

        if (del.data) {
            toast.success('Professor deletado com sucesso!', {
                className: 'toast-success'
            });
            this.fetchData(1, 20);
        }
    }



    saveChanges = async () => {
        let name = this.state.inputName;
        let lastName = this.state.inputLastName;
        let formation = this.state.inputFormationList;
        let image = this.state.inputImage;

        // Validações dos campos obrigatórios para salvar
        if (!this.validateRequiredFields(name, lastName)) {
            return;
        }

        let upload = await Api.teacherPanelEditor('', name, lastName, formation, image);


        upload = upload.teachers;
        if (Utils.checkForErrors(this, upload)) { return; }

        upload = upload.data.panel_editor;
        if (Utils.checkForErrors(this, upload)) { return; }

        upload = upload.data;
        if (Utils.checkForErrors(this, upload)) { return; }


        toast.success('Professor criado com sucesso!', {
            className: 'toast-success'
        });


        this.setState({
            inputImage: '',
            inputName: '',
            inputLastName: '',
            inputFormation: '',
            inputFormationList: []
        }, () => {
            this.setState({ showCreate: false });
            this.fetchData(1, 20);
        });
    }

    validateRequiredFields(name, lastName) {

        let requiredFieldsOK = true;
        if ((name == '') || (lastName == '')) {
            
            toast.info('Verifique os campos obrigatórios com (*) para poder salvar a edição do professor.', {
                className: 'toast-info'
            });

            requiredFieldsOK = false;
        }

        return requiredFieldsOK;
    }

};


export default ScreenTeachersList;