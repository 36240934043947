import React from 'react';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';

import Button from '../common/Button';



class EventPanelListItem extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showDelete: false,
            showPublish: false,
            link: '',
            linkAttendances: '',

            id: '',
            name: '',
            date_period: '',
            description_short: '',
            published: false
        }
    }

    componentDidMount() {

        const { id, name, description_short, published, date_period } = this.props.item;

        this.setState({
            id,
            name,
            description_short: description_short,
            link: `/eventos/editor?id=${id}`,
            linkAttendances: `/eventos/presencas?id=${id}&name=${encodeURIComponent(name)}&datePeriod=${encodeURIComponent(date_period)}`,
            published
        });

    };

    render() {
        return (
            <div className="event-itemList">
                <h3 className="event-title">{this.state.name}</h3>
                <div className="event-info">
                    <div className="event-description">
                        {this.state.description_short}
                    </div>
                    <div className="event-actions">
                        <Link style={{ width: '120px' }} className="button orange-bg" to={this.state.linkAttendances} title="Presença">
                            <FontAwesomeIcon icon='check' />Presença
                        </Link>
                        <Button
                            onClick={this.handleShowPublish}
                            style={{ width: '140px' }}
                            icon={this.state.published ? 'arrow-down' : 'arrow-up'}
                            type="orange"
                            title={this.state.published ? 'Despublicar' : 'Publicar'} />
                        <Link style={{ width: '120px' }} className="button orange-bg" to={this.state.link} title="Editar">
                            <FontAwesomeIcon icon='edit' />Editar
                        </Link>
                        <Button
                            onClick={this.handleShowDelete}
                            style={{ width: '120px' }}
                            icon='trash-alt'
                            type="orange"
                            title="Deletar" />
    
                        <Modal
                            show={this.state.showDelete}
                            onHide={this.handleCloseDelete}
                            backdrop="static"
                            className="modal">
                            <Modal.Header closeButton>
                                <Modal.Title className="modal-title">Deletar Evento</Modal.Title>
                            </Modal.Header>
    
                            <Modal.Body style={{ minHeight: '100px', textAlign: 'center' }}>
                                <h3>O evento deletado não poderá ser recuperado. Você deseja continuar?</h3>
                            </Modal.Body>
    
                            <Modal.Footer>
                                <Button onClick={this.handleDelete} type="orange" title="Sim" />
                                <Button onClick={this.handleCloseDelete} title="Não" />
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.showPublish}
                            onHide={this.handleClosePublish}
                            backdrop="static"
                            className="modal">
                            <Modal.Header closeButton>
                                <Modal.Title className="modal-title">{this.state.published ? 'Despublicar Evento' : 'Publicar Evento'}</Modal.Title>
                            </Modal.Header>
    
                            <Modal.Body style={{ minHeight: '100px', textAlign: 'center' }}>
                                <h3>Você deseja alterar a situação do evento?</h3>
                            </Modal.Body>
    
                            <Modal.Footer>
                                <Button onClick={this.handlePublish} type="orange" title="Sim" />
                                <Button onClick={this.handleClosePublish} title="Não" />
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            </div>
        );

    };



    // Handlers
    handleCloseDelete = () => {
        this.setState({ showDelete: false });
    };

    handleShowDelete = () => {
        this.setState({ showDelete: true });
    }
    
    handleDelete = () => {
        this.props.onEventDelete(this.state.id);
        this.handleCloseDelete();
    }

    // Handlers
    handleClosePublish = () => {
        this.setState({ showPublish: false });
    };

    handleShowPublish = () => {
        this.setState({ showPublish: true });
    }
    
    handlePublish = () => {
        this.props.onEventPublish(this.state.id, this.state.published);
        this.setState({published: !this.state.published});
        this.handleClosePublish();
    }

};



export default EventPanelListItem;