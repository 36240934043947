import React from 'react';

import Utils from '../Utils';
//import { timingSafeEqual } from 'crypto';



class DropdownItemWithImage extends React.Component {

    render() {
        const { item, type } = this.props;


        let name = Utils.capitalizeFirstLetter(item.name);
        let formationText = '';
        if (type === 1) {
            name += ` ${Utils.capitalizeFirstLetter(item.lastname)}`

            formationText = item.formation.map(formation => {
                return `${Utils.capitalizeFirstLetter(formation)}. `;
            })
        }
    
    
        return (
            <div 
                className={`dropdown-custom-item ${this.props.active ? 'active' : ''}`}
                onClick={() => this.props.onClick(item)}>
                <div
                    className='avatar-container'
                    style={{backgroundImage: `url(${item.image})`}} />
                <h5 className='name'>{name}</h5>
                {type === 1 ? <span className='formation'>{formationText}</span> : '' }
            </div>
            
        );
    }

}

class DropdownItem extends React.Component {

    render() {
        const { item } = this.props;

        let name = Utils.capitalizeFirstLetter(item.name);


        return (
            <div 
                className={`dropdown-custom-item ${this.props.active ? 'active' : ''}`}
                onClick={() => this.props.onClick(item)}>
                <h5 className='name'>{name}</h5>
            </div>

        );
    }
}



class DropdownCustomMenu extends React.Component {
    
    componentDidMount() {
        if (this.props.keyboardHover) {
            this.handleScroll(this.props.keyboardHover);
        }
        
    }

    componentDidUpdate() {
        if (this.props.keyboardHover) {
            this.handleScroll(this.props.keyboardHover);
        }
    }



    renderList = () => {

        let list = this.props.filteredItems.map((item, index) => {

            // Only text
            if (this.props.type === 3) {
                if (this.props.keyboardHover !== null && index === this.props.keyboardHover) {
                    return (
                        <div key={index} ref={index}>
                            <DropdownItem
                                type={this.props.type}
                                item={item}
                                onClick={this.handleClick}
                                active />
                        </div>
                    );
                }
    
                return (
                    <div key={index} ref={index}>
                        <DropdownItem
                            type={this.props.type}
                            item={item} 
                            onClick={this.handleClick}
                            active={false} />
                    </div>
                );
            }
            
            // Text and image
            if (this.props.keyboardHover !== null && index === this.props.keyboardHover) {
                return (
                    <div key={index} ref={index}>
                        <DropdownItemWithImage
                            type={this.props.type}
                            item={item}
                            onClick={this.handleClick}
                            active />
                    </div>
                );
            }

            return (
                <div key={index} ref={index}>
                    <DropdownItemWithImage
                        type={this.props.type}
                        item={item} 
                        onClick={this.handleClick}
                        active={false} />
                </div>
            );

        });

        if (!list) { return; }
        return list;

    }

    render() {
        if (!this.props.show) { return null; }

        return (
            <div className="custom-dropdown">
                {this.renderList()}
            </div>
        );
        
    }



    handleScroll = (item) => {
        if (!this.props.show) { return; }

        if (item === null) { return; }

        this.refs[item].scrollIntoView({block: 'nearest', behavior: 'smooth'});
    }

    handleClick = (item) => {
        this.props.onAddItem(item);
    }
}

export default DropdownCustomMenu;