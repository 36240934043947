import React from 'react';
import Api from '../apis/api';
import { uploadFile } from 'react-s3';
import { API_ROOT } from '../config';
import Utils from '../components/Utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import Button from '../components/common/Button';
import StampItem from '../components/js/StampItem';
import TextareaAutosize from 'react-autosize-textarea';

import { toast } from 'react-toastify';


class ScreenStampsList extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            showCreate: false,

            stamps: [],
            page: 1,

            inputImage: '',
            inputName: '',
            inputDescription: '',

            error: 0
        };
    };

    componentDidMount() {
        this.fetchData(1, 20);
    };

    async fetchData(page, pageSize) {
        this.setState({ loading: true });

        let stamps = await Api.stampsPanelList(page, pageSize);
        
        stamps = stamps.stamps;
        if (Utils.checkForErrors(this, stamps)) { return; };
        
        stamps = stamps.data.panel_list;
        if (Utils.checkForErrors(this, stamps)) { return; };
        
        stamps = stamps.data.list;
        this.setState({ stamps: stamps }, () => this.setState({ loading: false }));
    }

    // checkForErrors(element) {
    //     if (element.error_code !== 0) {
    //         this.setState({ error: element.error_code });
            
    //         toast.error(`Erro executando esta operação! Código de erro: ${element.error_code}`, {
    //             className: 'toast-error'
    //         });

    //         return true;
    //     }

    //     return false;
    // }


    renderList() {
        if (this.state.stamps.length === 0) { 
            return (
                <p style={{ fontFamily: 'Averta', fontSize: '1.2rem' }}>Não há selos cadastrados!</p>
            ); 
        }

        const stampsList = this.state.stamps.map((stamp) => {
            return (
                <StampItem
                    onStampDelete={this.handleStampDelete}
                    key={stamp.id}
                    item={stamp}
                />
            ); 
        });

        return stampsList;
    };


    render() {
        if (this.state.error !== 0) {
            return null;
        }

        return (
            <div className="main-content" style={{ textAlign: "center" }}>
                <div className="content-header fluid centered flex-between min-600">
                    <div></div>
                    <Button
                        onClick={this.handleShowCreate}
                        type="orange"
                        title="Adicionar novo selo" />

                    <Modal 
                        show={this.state.showCreate}
                        onHide={this.handleCloseCreate}
                        backdrop="static"
                        className="modal">
                        <Modal.Header closeButton>
                            <Modal.Title className="modal-title">Adicionar Selo</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <div
                                className="modal-avatar-container"
                                style={{backgroundImage: `url(${this.state.inputImage})`}}>
                                <input
                                    type="file"
                                    name="edit-avatar"
                                    id="edit-avatar"
                                    onChange={this.handleSelectedFile} />
                                <label htmlFor="edit-avatar">BUSCAR</label>
                            </div>
                            <div className="modal-card-data">
                                <div className="modal-input-group modal-input-name">
                                    <label>nome (*)</label>
                                    <input 
                                        type="text"
                                        value={this.state.inputName}
                                        onChange={e => this.setState({ inputName: e.target.value}) }
                                        placeholder="digite aqui o nome..." />
                                </div>
                                <div className="modal-input-group modal-input-formation">
                                    <label>descrição</label>
                                    <TextareaAutosize 
                                        value={this.state.inputDescription}
                                        onChange={e => this.setState({ inputDescription: e.target.value }) }
                                        placeholder="digite aqui a descrição..."
                                        async />
                                </div>
                            </div>
                        </Modal.Body>

                        <div style={{textAlign:'center'}}>
                            <label className="style-button" htmlFor="insert-picture"><FontAwesomeIcon icon='image' /> obs.: imagem (proporção 1:1, largura máxima 600px, tipo jpg ou png)</label>
                        </div>

                        <Modal.Footer>
                            <Button onClick={this.saveChanges} type="orange" title="Salvar" />
                        </Modal.Footer>
                    </Modal>
                </div>
                <div id="stamps-list">
                    {this.renderList()}
                </div>
            </div>
        );
    }

    handleCloseCreate = () => {
        this.setState({ showCreate: false });
    };

    handleShowCreate = () => {
        this.setState({ showCreate: true });
    };

    handleSelectedFile = (event) => {
        
        let file = event.target.files[0];
        let config = {
            bucketName: 'mri-ead',
            bucketRoot: 'https://s3-sa-east-1.amazonaws.com/mri-ead/',
            region: 'sa-east-1',
            accessKeyId: 'AKIAIBBX5O7CKQWW22HA',
            secretAccessKey: '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',
            dirName: 'images'
        };

        // TODO: LOADER SPINNER
        uploadFile(file, config, API_ROOT)
            .then(data => {
                this.setState({ inputImage: data.location });
                toast.success('Imagem enviada com sucesso!', {
                    className: 'toast-success'
                });
            }, function() {
                toast.error(`Erro subindo a imagem! Tente novamente!`, {
                    className: 'toast-error'
                });
            });
    
    };

    saveChanges = async () => {
        let name = this.state.inputName;
        let description = this.state.inputDescription;
        let image = this.state.inputImage;

        // Validações dos campos obrigatórios para salvar
        if (!this.validateRequiredFields(name)) {
            return;
        }

        let upload = await Api.stampPanelEditor('', name, description, image);

        upload = upload.stamps;
        if (Utils.checkForErrors(this, upload)) { return; }

        upload = upload.data.panel_editor;
        if (Utils.checkForErrors(this, upload)) { return; }

        upload = upload.data;
        if (Utils.checkForErrors(this, upload)) { return; }

        
        toast.success('Selo criado com sucesso!', {
            className: 'toast-success'
        });


        this.setState({
            inputImage: '',
            inputName: '',
            inputDescription: ''
        }, () => {
            this.setState({ showCreate: false });
            this.fetchData(1, 20);
        });
    }

    validateRequiredFields(name) {

        let requiredFieldsOK = true;
        if (name == '') {
            
            toast.info('Verifique os campos obrigatórios com (*) para poder salvar a edição do selo.', {
                className: 'toast-info'
            });

            requiredFieldsOK = false;
        }

        return requiredFieldsOK;
    }

    handleStampDelete = async (id) => {
        let del = await Api.stampPanelDelete(id);

        del = del.stamps;
        if (Utils.checkForErrors(this, del)) { return; };

        del = del.data.panel_rem;
        if (Utils.checkForErrors(this, del)) { return; };

        if (del.data) {
            toast.success('Selo deletado com sucesso!', {
                className: 'toast-success'
            });
            this.fetchData(1, 20);
        }
    }

};


export default ScreenStampsList;