import React from 'react';
import Api from '../apis/api';
import Utils from '../components/Utils';
import { uploadFile } from 'react-s3';
import { API_ROOT } from '../config';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import Button from '../components/common/Button';
import PlanItem from '../components/js/PlanItem';
import TextareaAutosize from 'react-autosize-textarea';

import { toast } from 'react-toastify';


class ScreenPlansList extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            showCreate: false,

            plans: [],
            page: 1,

            inputPrice: '',
            inputSpinsCount: '',

            error: 0
        };
    }

    componentDidMount() {
        this.fetchData(1, 20);
    };
    
    fetchData(page, pageSize) {
        this.setState({ loading: true, plans: [] },
            async () => {

                let plans = await Api.plansPanelList(page, pageSize);
        
                plans = plans.plans;
                if (Utils.checkForErrors(this, plans)) { return; };
                
                plans = plans.data.panel_list;
                if (Utils.checkForErrors(this, plans)) { return; };
        
                plans = plans.data.list;
                this.setState({ plans }, () => this.setState({ loading: false }));
            });
    }

    renderList() {
        if (this.state.plans.length === 0) { 
            return (
                <p style={{ fontFamily: 'Averta', fontSize: '1.2rem' }}>Não há planos cadastrados!</p>
            ); 
        }

        return this.state.plans.map((plan) => {
            return (
                <PlanItem
                    onPlanDelete={this.handlePlanDelete}
                    onSaveChanges={this.saveChanges}
                    key={plan.id}
                    item={plan}
                />
            ); 
        });
    };

    render() {
        if (this.state.error !== 0) {
            return null;
        }

        return (
            <div className="main-content" style={{ textAlign: "center" }}>
                <div className="content-header fluid centered flex-between min-600">
                    <div></div>
                    <Button
                        onClick={this.handleShowCreate}
                        type="orange"
                        title="Adicionar novo plano" />

                    <Modal 
                        show={this.state.showCreate}
                        onHide={this.handleCloseCreate}
                        backdrop="static"
                        className="modal">
                        <Modal.Header closeButton>
                            <Modal.Title className="modal-title">Adicionar Plano</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <div className="modal-card-data">
                                <div className="modal-input-group modal-input-name">
                                    <label>spins (*)</label>
                                    <input 
                                        type="text"
                                        value={this.state.inputSpinsCount}
                                        onChange={e => this.setState({ inputSpinsCount: e.target.value}) }
                                        placeholder="digite aqui a quantidade de spins..." />
                                </div>
                                <div className="modal-input-group modal-input-name">
                                    <label>valor (*)</label>
                                    <input
                                        type="text"
                                        value={this.state.inputPrice}
                                        onChange={e => this.setState({ inputPrice: e.target.value}) }
                                        placeholder="digite aqui o valor..." />
                                </div>
                            </div>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button onClick={this.handleSave} type="orange" title="Salvar" />
                        </Modal.Footer>
                    </Modal>
                </div>
                <div id="plans-list">
                    <table style={{width: '100%'}}>
                        <tbody>
                            <tr>
                                <td style={{width: '30%'}} />
                                <td style={{width: '10%'}} />
                                <td style={{width: '10%'}}>
                                    <h3 className="name">qtd de spins</h3>
                                </td>
                                <td style={{width: '10%'}}>
                                    <h3 className="name">valor</h3>
                                </td>
                                <td style={{width: '10%'}} />
                                <td style={{width: '30%'}} />
                            </tr>
                        </tbody>
                    </table>

                    <hr style={{height:1, width: '50%'}} />

                    {this.renderList()}
                </div>
            </div>
        );
    };

    handleCloseCreate = () => {
        this.setState({ showCreate: false });
    };

    handleShowCreate = () => {
        this.setState({ showCreate: true });
    };

    handlePlanDelete = async (id) => {
        let del = await Api.planPanelDelete(id);

        del = del.plans;
        if (Utils.checkForErrors(this, del)) { return; };

        del = del.data.panel_rem;
        if (Utils.checkForErrors(this, del)) { return; };

        if (del.data) {
            toast.success('Plano deletado com sucesso!', {
                className: 'toast-success'
            });
            this.fetchData(1, 20);
        }
    }

    handleSave = () => {

        let price = Number(this.state.inputPrice.toString().replace(',', '.'));
        let spinsCount = this.state.inputSpinsCount;

        this.saveChanges('', price, spinsCount);
    }

    saveChanges = async (id, price, spinsCount) => {
        
        price = Number(price.toString().replace(',', '.'));

        // Validações dos campos obrigatórios para salvar
        if (!this.validateRequiredFields(price, spinsCount)) {
            return;
        }

        let upload = await Api.planPanelEditor(id, price, spinsCount);


        upload = upload.plans;
        if (Utils.checkForErrors(this, upload)) { return; }

        upload = upload.data.panel_editor;
        if (Utils.checkForErrors(this, upload)) { return; }

        upload = upload.data;
        if (Utils.checkForErrors(this, upload)) { return; }


        if (id == '') {
            toast.success('Plano criado com sucesso!', {
                className: 'toast-success'
            });
        }
        else {
            toast.success('Plano cadastrado com sucesso!', {
                className: 'toast-success'
            });
        }

        this.setState({
            inputPrice: '',
            inputSpinsCount: ''
        }, () => {
            this.setState({ showCreate: false });
            this.fetchData(1, 20);
        });
    }

    validateRequiredFields(price, spinsCount) {

        let requiredFieldsOK = true;
        if ((price == '') || (price == 0) || (spinsCount == '') || (spinsCount == 0) || isNaN(price) || isNaN(spinsCount) || (Number(price) > 99999) || (Number(spinsCount) > 99999)) {
            
            toast.info('Verifique os campos obrigatórios com (*) para poder salvar a edição do plano.', {
                className: 'toast-info'
            });

            requiredFieldsOK = false;
        }

        return requiredFieldsOK;
    }

};


export default ScreenPlansList;