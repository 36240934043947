import React from 'react';
import Api from '../apis/api';
import Utils from '../components/Utils';

import Line from '../components/common/Line';
import Button from '../components/common/Button';

import DatePicker,{ registerLocale, setDefaultLocale } from "react-datepicker";
import { pt } from 'date-fns/locale';
registerLocale('pt', pt);
setDefaultLocale('pt');

let moment = require('moment');
let trLocale = require('moment/locale/pt-br');
moment.defineLocale('pt-br', trLocale);



class ScreenFinances extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: 0,

            market: [],
            name: '',
            dateStart: null,
            dateEnd: null
        };

    }

    componentDidMount() {
        this.fetchData('', '', '');
    }

    dateParse(create_time){
        
        let createTime = new Date(1970, 0, 1, 0, 0, 0, 0);
        createTime.setSeconds(create_time);
        createTime.setHours(createTime.getHours() - 3);
        
        return createTime;
    }

    async fetchData(name, dateStart, dateEnd) {
        this.setState({ loading: true, market: [] });

        let market = await Api.marketPanelList(name, dateStart, dateEnd);

        market = market.market;
        if (Utils.checkForErrors(this, market)) { return; };

        market = market.data.panel_list;
        if (Utils.checkForErrors(this, market)) { return; };

        market = market.data;
        for (var i = 0; i < market.length; i++) {
            market[i]["create_date"] = moment(this.dateParse(market[i]["create_time"])).format("DD/MM/YYYY");
            market[i]["type_payment"] = (market[i]["id_course"] != 0 ? 'Curso' : (market[i]["id_event"] != 0 ? 'Evento' : 'Crédito'));
         }

        this.setState({ market }, () => this.setState({ loading: false }));
    }



    render() {

        return (
            <div className="main-content" style={{ textAlign: 'center', marginLeft: '1rem', marginRight: '1rem' }}>
                
                <div className='form min-600 centered' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div className="input-group-section" style={{ width: '30%', margin: '1rem' }}>
                        <label className="label-section">nome</label>
                        <input 
                            type="text"
                            value={this.state.name}
                            onChange={e => this.setState({ name: e.target.value}) }
                            placeholder="digite aqui o nome..." />
                    </div>
                    <div className="input-group-section" style={{ width: '30%', margin: '1rem' }}>
                        <label className="label-section">de</label>
                        <DatePicker
                            selected={this.state.dateStart}
                            onChange={date => { this.handleChangeDate('start', date) }}
                            dateFormat="dd/MM/yyyy" placeholderText="dd/mm/aaaa"
                        />
                    </div>
                    <div className="input-group-section" style={{ width: '30%', margin: '1rem' }}>
                        <label className="label-section">a</label>
                        <DatePicker
                            selected={this.state.dateEnd}
                            onChange={date => { this.handleChangeDate('end', date) }}
                            dateFormat="dd/MM/yyyy" placeholderText="dd/mm/aaaa"
                        />
                    </div>
                    <Button
                        style={{ width: '30%' }}
                        onClick={() => {
                            let dateStart = 0, dateEnd = 0;

                            if (this.state.dateStart !== null) {
                                dateStart = new Date(this.state.dateStart.getUTCFullYear() + '-' + (this.state.dateStart.getUTCMonth() + 1) + '-' + this.state.dateStart.getUTCDate() + ' 00:00:00').getTime() / 1000;
                            }
                            if (this.state.dateEnd !== null) {
                                dateEnd = new Date(this.state.dateEnd.getUTCFullYear() + '-' + (this.state.dateEnd.getUTCMonth() + 1) + '-' + this.state.dateEnd.getUTCDate() + ' 23:59:59').getTime() / 1000;
                            }
                            this.fetchData(this.state.name, dateStart, dateEnd);
                        }}
                        type="orange"
                        title="Filtrar" />
                </div>
                <div id='transactions-log'>
                    <Line 
                        data={[
                            {content: 'Usuários', size: 20}, 
                            {content: 'E-mail', size: 20}, 
                            {content: 'Atividade', size: 0},
                            {content: 'Tipo', size: 5}, 
                            {content: 'Data', size: 8}, 
                            {content: 'Spins', size: 8}, 
                            {content: 'R$', size: 8}
                        ]}
                        font='bold'
                    />
                    {this.renderMarketList()}
                </div>

            </div>
        );
    }

    renderMarketList() {

        if (this.state.market.length === 0) { 
            return (
                <p style={{ fontFamily: 'Averta', fontSize: '1.2rem' }}>Informações não localizadas!</p>
            ); 
        }

        let list = this.state.market.map((item, i) => {

            let description = item.description.replace(/&#39;/gim, "'");

            let dataArray = [];
            dataArray.push({content: item.user_name, size: 20});
            dataArray.push({content: item.email, size: 20});
            dataArray.push({content: description, size: 0});
            dataArray.push({content: item.type_payment, size: 5});
            dataArray.push({content: item.create_date, size: 8});
            dataArray.push({content: item.spin_qty, size: 8});
            dataArray.push({content: item.value.toFixed(2), size: 8});

            let classLine = '';
            if (i%2 !== 0) {
                classLine = 'white-line';
            } else {
                classLine = 'orange-line';
            }
            
            //console.log(dataArray);

            return (
                <Line 
                    classStyle={classLine}
                    key={i}
                    data={dataArray}
                />
            );
        });

        return list;
    }



    handleChangeDate = (type, date) => {
        if (type === 'start') {
            this.setState({ dateStart: date });
        } else {
            this.setState({ dateEnd: date });
        }
    }
}



export default ScreenFinances;