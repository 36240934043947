import React from 'react';
import Api from '../apis/api';
import Utils from '../components/Utils';

import { Link } from 'react-router-dom';

import EventPanelListItem from '../components/js/EventPanelListItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class ScreenEventsList extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            events: [],
    
            published: '',
            query: '',
            page: 1,
            order: '',
    
            error: 0
        };
    }

    componentDidMount() {
        this.fetchData('', '', 1, 100, '');
    };

    render() {
        if (this.state.error !== 0) {
            return null;
        }

        return (
            <div className="main-content min-600 centered" style={{ textAlign: "center" }}>
                <div className="content-header centered flex-between min-600">
                    <div></div>
                    <Link
                        style={{ width: '240px' }}
                        className="button orange-bg"
                        to={'/eventos/editor'}
                        title="Adicionar novo evento" >
                        <FontAwesomeIcon icon='plus' />Adicionar novo evento
                    </Link>
                </div>
                <div id="events-list" style={{ minHeight: '100px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    {this.renderList()}
                </div>
            </div>
        );
    };



    async fetchData(published, query, page, pageSize, order) {
        this.setState({ loading: true });

        let events = await Api.eventsPanelList(published, query, page, pageSize, order);

        events = events.events;
        if (Utils.checkForErrors(this, events)) { return; };

        events = events.data.panel_list;
        if (Utils.checkForErrors(this, events)) { return; };

        events = events.data.list;
        //console.log('events', events);
        this.setState({ events: events }, () => this.setState({ loading: false }));
    };

    // checkForErrors(element) {
    //     if (element.error_code !== 0) {
    //         this.setState({ error: element.error_code });

    //         toast.error(`Erro executando esta operação! Código de erro: ${element.error_code}`, {
    //             className: 'toast-error'
    //         });

    //         return true;
    //     }

    //     return false;
    // }



    renderList() {
        if (this.state.events.length === 0) { 
            return (
                <p style={{ fontFamily: 'Averta', fontSize: '1.2rem' }}>Não há eventos cadastrados!</p>
            ); 
        }

        const eventsList = this.state.events.map((event) => {
            //console.log(event);
            return (
                <EventPanelListItem
                    onEventDelete={this.handleEventDelete}
                    onEventPublish={this.handleEventPublish}
                    key={event.id}
                    item={event}
                />
            ); 
        });

        return eventsList;
    };
    
    handleEventDelete = async (id) => {
        let del = await Api.eventsPanelDelete(id);

        del = del.events;
        if (Utils.checkForErrors(this, del)) { return; };

        del = del.data.panel_rem;
        if (Utils.checkForErrors(this, del)) { return; };

        if (del.data) {
            this.fetchData('', '', 1, 20, '');
        }
    }
    
    handleEventPublish = async (id, published) => {
        let pub = await Api.eventsPanelPublish(id, !published);

        pub = pub.events;
        if (Utils.checkForErrors(this, pub)) { return; };

        pub = pub.data.panel_publish;
        if (Utils.checkForErrors(this, pub)) { return; };

        if (pub.data) {
            this.fetchData('', '', 1, 20, '');
        }
    }

};


export default ScreenEventsList;