import { toast } from 'react-toastify';


class Utils {

    capitalizeFirstLetter(text) {
        if ((!text) || (text === '')) { return text }
        
        return text.charAt(0).toUpperCase() + text.slice(1);
    };



    checkForErrors(that, element) {
        if ('error_code' in element && element.error_code !== 0) {
            that.setState({ error: element.error_code });

            toast.error(`Erro executando esta operação! Código de erro: ${element.error_code}`, {
                className: 'toast-error'
            });
            
            return true;
        }

        return false;
    };



    reduceString(string) {
        string = string + '';
        if (string != null && string.length > 3) {
            return string.substring(0, string.length - 3) + 'k';
        } else return string;
    }

}


export default (new Utils());